import React from "react";

import {
  Container,
  Box
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class DashBoardInfoCount extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="sectionDashBoardInfoCount">
        <Container className="dashboardContainer">
          <Box className="dashboradCountBox dasboardMobile" style={{alignItems:"center"}}>
            {/* <Box> */}
              <div className="dashboradCount">
                <h2>600,000 +</h2>
                <p>Students helped</p>
              </div>
            {/* </Box> */}
            {/* <Box> */}
              <div className="dashboradCount">
                <h2 className="mobileTextEnd">150,000 +</h2>
                <p className="mobileTextEnd">Programs</p>
              </div>
            {/* </Box> */}
            {/* <Box> */}
              <div className="dashboradCount">
                <h2>20,000 +</h2>
                <p>Partners</p>
              </div>
            {/* </Box> */}
            {/* <Box> */}
              <div className="dashboradCount mobileTextEnd">
                <h2 className="mobileTextEnd">1750 +</h2>
                <p className="mobileTextEnd">Universities</p>
              </div>
            {/* </Box> */}
          </Box>
        </Container>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
