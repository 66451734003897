import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
// Customizable Area End

import UniversityPageController, {
  Props,
  commonWebStyle
} from "./UniversityPageController";

export default class InsightCards extends UniversityPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  insightCards = [
    {
      title: "Local Support",
      description:
        "Our international recruitment experts are there to support you every step of the way.*",
      image: "insight1.svg",
      background: "#F1F5F9"
    },
    {
      title: "Industry Education",
      description:
        "Expanding Partners education through guided online courses that strengthen the quality...",
      image: "insight2.svg",
      background: "#D9770614"
    },
    {
      title: "Events and Webinars",
      description:
        "Regular online webinars, training, and events to keep you updated on the latest trends and regulations.",
      image: "insight3.svg",
      background: "#05966914"
    },
    {
      title: "Data and Insights",
      description:
        "Industry leading insights and knowledge that helps you plan, expand, and achieve your goals.",
      image: "insight4.svg",
      background: "#2F57A514"
    }
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box
          style={{ position: "relative", bottom: "-200px", right: "-100px" }}
        >
          <Box style={commonWebStyle.leftBlueBackground} />
        </Box>
        <Box style={{ width: "100%" }}>
          <Box style={webStyle.mainBox}>
            <h1 style={webStyle.heading}>
              Insights You Can{" "}
              <span style={webStyle.textHighlight}>Count On</span>
            </h1>
            <Box style={webStyle.cardBox}>
              {this.insightCards.map((card, index) => (
                <Box
                  key={index}
                  style={{
                    ...webStyle.cardsListItem,
                    ...{ background: card.background }
                  }}
                >
                  <p style={webStyle.cardTitle}>{card.title}</p>
                  <p style={webStyle.cardDescription}>{card.description}</p>
                  <img src={require("../../assets/" + card.image)} />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainBox: {
    width: "100%",
    maxWidth: "1454px",
    margin: "0 auto",
    marginTop: "150px"
  },
  heading: {
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "50px",
    lineHeight: "120%",
    textAlign: "center" as const,
    color: "#060A14",
    marginBottom: "100px"
  },
  textHighlight: {
    color: "#2F57A5"
  },
  cardsList: {
    width: "100%"
  },
  cardBox: {
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "wrap" as const,
    gap: "48px"
  },
  cardsListItem: {
    height: "auto",
    padding: "84px 79px",
    borderRadius: "24px"
  },
  iconBox: {
    width: "40px",
    marginRight: "24px"
  },
  cardTitle: {
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "120%",
    color: "#060A14",
    marginBottom: "24px",
    textAlign: "center" as const
  },
  cardDescription: {
    width: "538px",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "30px",
    letterSpacing: "0.05px",
    color: "#334155",
    marginBottom: "48px",
    textAlign: "center" as const
  },
  imageBox: {
    display: "flex",
    justifyContent: "end",
    alignItems: "start"
  }
};
// Customizable Area End
