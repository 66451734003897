import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  OurStoryImg1,
  OurStoryImg2,
  OurStoryImg3
} from "./assets"; 
 
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  expandedAccordion: string;
  value: string;
  videosData: any;
  isFilterVideoPlaying: boolean;
  videoIdToPlay: any;
  isOverlayVideoPlaying: boolean;
  vidFirstPlaying: boolean;
  vidSecondPlaying: boolean;
  selectedYearData: any;
  selectedYear: string;
  activeIndex: any;
  isVideoVisible: boolean,
  isVisible: boolean;
  activeImage: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.toggleVidPlay = this.toggleVidPlay.bind(this);
    this.handleOverlayVideoPlay = this.handleOverlayVideoPlay.bind(this);
    this.handlePassionateSectionVid = this.handlePassionateSectionVid.bind(this);
    this.subScribedMessages = [
    ];

    this.state = {
      expandedAccordion: "students",
      value: 'All ',
      videosData: [],
      isFilterVideoPlaying: false,
      videoIdToPlay: '',
      isOverlayVideoPlaying: false,
      vidFirstPlaying: false,
      vidSecondPlaying: false,
      selectedYearData: [],
      selectedYear: '2022',
      activeIndex: 0,
      isVideoVisible: false,
      isVisible: false,
      activeImage: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean = true
  ) => {
    this.setState({ expandedAccordion: isExpanded ? panel : "" });
  };
  handleClick(val: any) {
    this.setState({ value: val });
  }
  toggleVidPlay(id: any){
    this.setState({ isFilterVideoPlaying: true, videoIdToPlay: id })
  }

  fun =()=>{

    const interval = setInterval(() => {
      this.setState({activeIndex:(prevItem: number)=> (prevItem + 1) % 6})
    }, 2000);
    return interval
  }
 async  componentDidMount() {
    this.filterVideos()
    setInterval(() => {
      this.setState(prevState => {
        return {
          activeIndex: (prevState.activeIndex + 1) % 6
        };
      })
    }, 1000);
    window.addEventListener('scroll', this.handleScroll);
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.state.value !== prevState.value) {
      this.filterVideos()
    }
  }
  filterVideos() {
  let filter = this.state.value

const all = [
  {
    id: 1,
    source: 'https://designsupply-web.com/samplecontent/vender/codepen/20181014.mp4',
    partnerAs: 'Partner as a',
    as: 'Student'
  },
  {
    id: 2,
    source: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
    partnerAs: 'Partner as a',
    as: 'Parner'
  },
  {
    id: 3,
    source: 'https://designsupply-web.com/samplecontent/vender/codepen/20181014.mp4',
    partnerAs: 'Partner as a',
    as: 'University'
  },
  {
    id: 4,
    source: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
    partnerAs: 'Partner as a',
    as: 'Student'
  },
  {
    id: 5,
    source: 'https://designsupply-web.com/samplecontent/vender/codepen/20181014.mp4',
    partnerAs: 'Partner as a',
    as: 'University'
  },
  {
    id: 6,
    source: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
    partnerAs: 'Partner as a',
    as: 'Partner'
  },
];

const Students = [
  {
    id: 7,
    source: 'https://designsupply-web.com/samplecontent/vender/codepen/20181014.mp4',
    partnerAs: 'Partner as a',
    as: 'Student'
  },
  {
    id: 8,
    source: 'https://designsupply-web.com/samplecontent/vender/codepen/20181014.mp4',
    partnerAs: 'Partner as a',
    as: 'Student'
  },
  {
    id: 9,
    source: 'https://designsupply-web.com/samplecontent/vender/codepen/20181014.mp4',
    partnerAs: 'Partner as a',
    as: 'Student'
  },
  {
    id: 18,
    source: 'https://designsupply-web.com/samplecontent/vender/codepen/20181014.mp4',
    partnerAs: 'Partner as a',
    as: 'Student'
  },
];

const Partners = [
  {
    id: 10,
    source: 'https://designsupply-web.com/samplecontent/vender/codepen/20181014.mp4',
    partnerAs: 'Partner as a',
    as: 'Partner'
  },
  {
    id: 11,
    source: 'https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    partnerAs: 'Partner as a',
    as: 'Partner'
  },
  {
    id: 12,
    source: 'https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    partnerAs: 'Partner as a',
    as: 'Partner'
  },
  {
    id: 16,
    source: 'https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    partnerAs: 'Partner as a',
    as: 'Partner'
  },

];

const universities = [
  {
    id: 13,
    source: 'https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    partnerAs: 'Partner as a',
    as: 'University'
  },
  {
    id: 14,
    source: 'https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    partnerAs: 'Partner as a',
    as: 'University'
  },
  {
    id: 15,
    source: 'https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    partnerAs: 'Partner as a',
    as: 'University'
  },
  {
    id: 17,
    source: 'https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    partnerAs: 'Partner as a',
    as: 'University'
  },
];

switch (filter) {
  case 'All ':
    this.setState({ videosData: all });
    break;
  case '/ Students ':
    this.setState({ videosData: Students })
    break;
  case '/ Partners ':
    this.setState({ videosData: Partners })
    break;
  case '/ Universities':
    this.setState({ videosData: universities })
    break;
  default:
    this.setState({ videosData: all })
    break;
}
  }
  handleOverlayVideoPlay() {
    this.setState(prevState => ({
      isOverlayVideoPlaying: !prevState.isOverlayVideoPlaying
    }))
  }

  handlePassionateSectionVid(val: string){
    if(val === 'video1'){
     this.setState({ vidFirstPlaying: true})
    }

    if(val === 'video2'){
      this.setState({ vidSecondPlaying: true})
    }
  }

  handleYearFilter(year: any){
    this.setState({ selectedYear: year})

    switch(year){
      case '2022':
        this.setState({ selectedYearData: [] });
        break;
      case '2023':
        this.setState({ selectedYearData: [] })
        break;
      case '2024':
        this.setState({ selectedYearData: [] })
        break;
      case '2025':
        this.setState({ selectedYearData: [] })
        break;
      default:
        this.setState({ selectedYearData: [] })
        break;  
    }
  }

  async componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY >= 100) {
      this.setState({ isVideoVisible: true });
    } else {
      this.setState({ isVideoVisible: false });
    }
  };

  handleVisibilityChange = (isVisible: boolean) => {
    this.setState({ isVisible });
  };
  
  handleImageChange = (index: number) => {
    this.setState({ activeImage: index });
  };
  
   imagesArray = [
    {
      id:1,
      src:OurStoryImg1,
      title:"image1"
  
    },
    {
      id:2,
      src:OurStoryImg2,
      title:"image1"
  
  },
  {
    id:3,
    src:OurStoryImg3,
    title:"image1"
  }
  ]
  // Customizable Area End
}
