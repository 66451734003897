import React from "react";

import {
    // Customizable Area Start
    Box,
    Grid
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {

} from "./assets";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class AboutUsVideoBanner extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <div id="AboutUsVideoBanner" className="AboutUsVideoBanner" >
                {/* <div className={`videoBannerContainer ${this.state.scrollStyle}`}>
                    <video className="videoBannerVideo" autoPlay loop muted>
                        <source src="https://designsupply-web.com/samplecontent/vender/codepen/20181014.mp4
                    " type="video/mp4" />
                    </video>
                </div> */}
                <Grid container spacing={2}>
                <Grid item xs={12} lg={4}></Grid>
                <Grid item sm={12} md={12} lg={4}>
                  <Box style={{ marginBottom: "32px", textAlign: "center"}}>
                    <span className="sectionHeading blackHeading">
                    We're
                    </span> &nbsp;
                    <span className="sectionHeading blueHeading">
                    StudyBoard
                    </span>
                  </Box>
                  <Box>
                    <p className={"subHeading"}>
                        We empower people around the world to study abroad and access the best education.
                    </p>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={4}></Grid>
              </Grid>
                <Grid container>
                <Grid item xs={12} lg={2}></Grid>
                    <Grid item xs={12} lg={8} style={{ textAlign: "center" }}>
                        <div style={webStyle.elipseImage}>
                            <video 
                                style={this.state.isVideoVisible ? webStyle.fullElipseVideo : webStyle.elipseVideo}
                                src="https://www.shutterstock.com/shutterstock/videos/1083186550/preview/stock-footage-a-group-of-asian-high-school-students-uses-laptops-to-program-a-renewable-energy-project-for-a.webm"
                                autoPlay
                                loop
                                muted
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={2}></Grid>
                </Grid>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    elipseImage: {
        width: "100%",
        height: "600",
        borderTopLeftRadius: "800px",
        borderTopRightRadius: "800px",
        borderBottom: "0",
        marginTop: "100px",
        marginBottom: "80px",
        objectfit: "cover",
    },
    elipseVideo: {
        width: "100%",
        height: "600",
        borderTopLeftRadius: "800px",
        borderTopRightRadius: "800px",
        clipPath: 'circle(30vw at 50% 30vw)',
        transition: 'clip-path .5s,-webkit-clip-path .5s',
        objectfit: "cover",
    },
    fullElipseVideo: {
        width: 'calc(99vw + 3px)',
        height: "maxContent",
        objectFit: "cover" as const,
        position: "absolute" as const,
        left: "0px",
        transition: 'clip-path .3s,-webkit-clip-path .5s',
    },
}
// Customizable Area End
