import React from "react";

import {
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import './AboutUsPage.css'; 
import './landingpage.css';

import LeadershipTwoColumnLayout from "./LeadershipTwoColumnLayout.web";
import LeadershipSlider from "./LeadershipSlider.web";
import LeadershipTopSection from "./LeadershipTopSection.web";
import LeadershipApplyNow from "./LeadershipApplyNow.web";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";


export default class LeadershipPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="AboutUsPage" onScroll={this.handleScroll}>
        <LeadershipTopSection navigation={undefined} id="" />
        <LeadershipTwoColumnLayout navigation={undefined} id={""} />
        <LeadershipSlider navigation={undefined} id=""/>
        <LeadershipApplyNow navigation={undefined} id={""} />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
