import React from "react";

import {
    Container,
    Box,
    // Customizable Area Start
    withStyles,
    Tooltip
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    roudedhighilighted,
    roudedNormal
} from "./assets";
import './landingpage.css'

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



const StyledTooltip = withStyles({
    tooltip: {
        backgroundColor: '#2F57A5',
        color: 'white',
        '.MuiTooltip-arrow': {
            backgroundColor: '#2F57A5'
        }
    },
})(Tooltip);

// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class YearWiseSlider extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                },
                {
                    breakpoint: 770,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                    }
                },

            ]
        };

        return (
            // Customizable Area Start
            <>
                <div className="VideoContentSection sectionpadding">
                    <div className="sectionBackgroundLeft" >
                        <Container style={{ maxWidth: "1500px" }}>
                            <Box className="aboutUsSlider">
                                <div className="leftBox">
                                    <h1 className="yearwiseHeading blackHeading" style={{ marginBottom: "32px" }}>
                                        Our {' '}
                                        <span className="sectionHeading blueHeading">
                                            History
                                        </span>
                                    </h1>
                                    <p
                                        className={"yearWiseSubHeading"}
                                        style={{ marginBottom: '50px' }}
                                    >
                                        Our 1,500+ team members (and growing) are as diverse as the students that we support
                                    </p>
                                </div>
                                <div className={"rightBoxRoot"}>
                                    <div className="rightBox">
                                        <div className="dashedLine"></div>
                                        <div className="circles">
                                            <div>
                                                <StyledTooltip title="2022" arrow={true} placement="top" >
                                                    {this.state.selectedYear === '2022' ?
                                                        <img src={roudedhighilighted} alt={""} /> :
                                                        <img src={roudedNormal} alt={""} onClick={() => this.handleYearFilter('2022')} data-test-id="circleImg"/>}
                                                </StyledTooltip>
                                            </div>
                                            <div>
                                                <StyledTooltip title="2023" arrow={true} placement="top">
                                                    {this.state.selectedYear === '2023' ?
                                                        <img src={roudedhighilighted} alt={""} /> :
                                                        <img src={roudedNormal} alt={""} onClick={() => this.handleYearFilter('2023')} data-test-id="circleImg1"/>}
                                                </StyledTooltip>
                                            </div>
                                            <div>
                                                <StyledTooltip title="2024" arrow={true} placement="top">
                                                    {this.state.selectedYear === '2024' ?
                                                        <img src={roudedhighilighted} alt={""} /> :
                                                        <img src={roudedNormal} alt={""} onClick={() => this.handleYearFilter('2024')} data-test-id="circleImg2"/>}
                                                </StyledTooltip>
                                            </div>
                                            <div>
                                                <StyledTooltip title="2025" arrow={true} placement="top">
                                                    {this.state.selectedYear === '2025' ?
                                                        <img src={roudedhighilighted} alt={""} /> :
                                                        <img src={roudedNormal} alt={""} onClick={() => this.handleYearFilter('2025')} data-test-id="circleImg3" />}
                                                </StyledTooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Box>

                            {/* slider section */}
                            <Box
                                className="sliderMainContainer"
                            >
                                <Slider {...settings}>
                                    {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((item, index) => (
                                        <div className="upperMonthContainer" key={index}>
                                            <div className="monthBox" >
                                                <h1>{item}</h1>
                                                <div className="innerContent">
                                                    <h3>{item} {" " + this.state.selectedYear}</h3>
                                                    <p>{item === 'January'? 'Launch of StudyBoard': '1,500+ team members'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </Box>
                        </Container>
                    </div>
                </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
