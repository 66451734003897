import React from "react";

import {
  Container,
  Box,
  Grid
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Students, Universities, Partners, rightArrow } from "./assets";
import { Link } from "react-router-dom";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class ThreeColumnCards extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div style={{ position: "relative" }}>
          <div className="side-BackGround-Effect-left"></div>
        </div>
        <Container style={{ maxWidth: "1500px" }}>
          <div className="sectionThreeColumnCard sectionPadding">
            <div className="threeColumnCardsTop sectionHeading">
              <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={12}>
                <Box className="MoibleCoulmn headSubMargin">
                    <span className="sectionHeading blueHeading">
                      Get Started With
                    </span>
                    &nbsp;
                    <span className="sectionHeading blackHeading threeCoulmnMobileSubHeading">
                      StudyBoard
                    </span>
                  </Box>
                  <Box>
                    <p className={"subHeading"}>
                      We believe that education should be accessible to all.
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <div className="threeColumnCardsBottom">
              <Container maxWidth="xl">
                <div className="cardRow">
                  <Box className="cardColumn">
                    <Box className="cardColumnInner">
                      <div className="cardImg">
                        <img src={Students} alt="" />
                      </div>
                      <div className="cardContent">
                        <h2>Student</h2>
                        <p>
                          Are you a student looking to study abroad in Canada, the
                          United States, the United Kingdom...
                        </p>
                      </div>
                      <div className="cardAction">
                        <Link className="cardLink" to="">
                          Explore more
                        </Link>
                        <img src={rightArrow} alt="" />
                      </div>
                    </Box>
                  </Box>
                  <Box className="cardColumn">
                    <Box className="cardColumnInner">
                      <div className="cardImg">
                        <img src={Universities} alt="" />
                      </div>
                      <div className="cardContent">
                        <h2>Universities</h2>
                        <p>
                          Become an StudyBoard partner university to diversify your campus by attracting...
                        </p>
                      </div>
                      <div className="cardAction">
                        <Link className="cardLink" to="">
                          Explore more
                        </Link>
                        <img src={rightArrow} alt="" />
                      </div>
                    </Box>
                  </Box>
                  <Box className="cardColumn">
                    <Box className="cardColumnInner">
                      <div className="cardImg">
                        <img src={Partners} alt="" />
                      </div>
                      <div className="cardContent">
                        <h2>Partners</h2>
                        <p>
                          Do you recruit prospective students who want to study in Canada, the United States...
                        </p>
                      </div>
                      <div className="cardAction">
                        <Link className="cardLink" to="">
                          Explore more
                        </Link>
                        <img src={rightArrow} alt="" />
                      </div>
                    </Box>
                  </Box>
                </div>
              </Container>
            </div>
          </div>
        </Container>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
