import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
// Customizable Area End

import StudentPageController, {
  Props,
  commonWebStyle
} from "./StudentPageController";

export default class StudentSuccessStories extends StudentPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box
        className="sectionVideoOverlay sectionPadding"
        style={{ width: "100%", maxWidth: "1438px", margin: "0 auto" }}
      >
        <Box style={webStyle.VideoOverlayBox}>
          {!this.state.isOverlayVideoPlaying ? (
            <img
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "12px"
              }}
              src={require("../../assets/video-thumbnail.jpg")}
            />
          ) : (
            <video
              loop
              autoPlay
              controls
              style={{
                width: "100%",
                height: "669px",
                objectFit: "cover",
                borderRadius: "12px"
              }}
            >
              <source
                src="https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4"
                type="video/mp4"
              />
            </video>
          )}
          <Box
            style={
              this.state.isOverlayVideoPlaying
                ? webStyle.hideVideoOverlayCard
                : webStyle.videoOverlayCard
            }
          >
            <Box style={webStyle.playIconBox}>
              <img
                style={webStyle.playIcon}
                src={require("../../assets/playIcon16.svg")}
                alt=""
                onClick={this.handleOverlayVideoPlay}
              />
            </Box>
            <Box className="videoOverlayTextInner">
              <h1 style={webStyle.heading}> Student Success Stories</h1>
              <Box className="">
                <Box style={webStyle.buttonDiv}>
                  <Link to="" href="/" style={webStyle.getStartedButton}>
                    Get Started Today{" "}
                    <img src={require("../../assets/arrow-blue.svg")} alt="" />
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {this.state.isOverlayVideoPlaying && (
          <Box className="overlayVideoLink">
            <Box style={webStyle.buttonDivVideoPlaying}>
              <Link to="" href="/" style={webStyle.getStartedButton}>
                Get Started Today{" "}
                <img src={require("../../assets/arrow-blue.svg")} alt="" />
              </Link>
            </Box>
          </Box>
        )}
        <Box style={{ position: "relative", bottom: "70px", right: "-170" }}>
          <Box style={commonWebStyle.rightBlueBackground} />
        </Box>
        <Box
          style={{
            position: "relative",
            right: "200px",
            top: "-75px",
            zIndex: -1
          }}
        >
          <Box style={commonWebStyle.rightDoottedBackground}>
            <img
              style={{ width: "250px", top: "230px", right: "230px" }}
              src={require("../../assets/dot-bg-small.svg")}
            />
          </Box>
        </Box>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  heading: {
    textAlign: "center" as const,
    fontSize: "66px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "120%",
    marginBottom: "28px"
  },
  VideoOverlayBox: {
    width: "100%",
    borderRadius: "12px",
    height: "669px"
  },
  videoOverlayCard: {
    width: "650px",
    height: "350px",
    padding: "80px 40px 40px 40px",
    borderRadius: "12px",
    background: "#FFF",
    display: "block",
    position: "relative",
    top: "-240px",
    left: "50px"
  },
  hideVideoOverlayCard: {
    display: "none"
  },
  playIconBox: {
    width: "100%",
    position: "absolute" as const,
    top: "-70px",
    display: "flex",
    justifyContent: "center",
    marginLeft: "-40px",
    cursor: "pointer"
  },
  playIcon: {
    width: "138px",
    height: "138px"
  },
  buttonDivVideoPlaying: {
    width: "100%",
    textAlign: "left" as const
  },
  buttonDiv: {
    width: "100%",
    textAlign: "center" as const
  },
  getStartedButton: {
    margin: "0 auto",
    fontSize: "13px",
    fontFamily: "ZonaPro",
    fontWeight: 700,
    lineHeight: "24px",
    height: "44px",
    width: "max-content",
    padding: "8px 16px",
    borderRadius: "8px",
    border: "1px solid #E2E8F0",
    background: "#FFF",
    textDecoration: "none",
    color: "#2F57A5"
  }
};
// Customizable Area End
