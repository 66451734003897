import React from "react";
// Customizable Area Start
import { Grid, Box } from "@material-ui/core";
// Customizable Area End

import UniversityPageController, {
  Props,
  commonWebStyle
} from "./UniversityPageController";

export default class ThreeCardSection extends UniversityPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  cards = [
    {
      title: "Quality Applications",
      description:
        "For every hundred students, we get fifteen more enrolled than others.",
      tag: "Conversion",
      image: "card-img1.svg"
    },
    {
      title: "Unmatched Diversity",
      description: "StudyBoard students come from more than 150 countries.",
      tag: "Diversity",
      image: "card-img2.svg"
    },
    {
      title: "Less Administration. More Education",
      description: "StudyBoard students come from more than 150 countries.",
      tag: "Productivity / ROI",
      image: "card-img3.svg"
    }
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box style={{ position: "relative", bottom: "130px", right: "-100px" }}>
          <Box style={commonWebStyle.leftBlueBackground} />
        </Box>
        <Box
          style={{
            position: "relative",
            right: "350px",
            bottom: "10px",
            zIndex: -1
          }}
        >
          <Box style={commonWebStyle.rightDoottedBackground}>
            <img
              style={{ width: "100%", top: "0px", right: "0px" }}
              src={require("../../assets/bg-triangles.svg")}
            />
          </Box>
        </Box>
        <Box style={{ width: "100%" }}>
          <Grid container spacing={2} style={webStyle.mainGrid}>
            {this.cards.map((item, index) => (
              <Grid
                item
                lg={4}
                md={4}
                xs={12}
                key={index}
                style={webStyle.cardGridParent}
              >
                <Box style={webStyle.cardMainBox}>
                  <img
                    style={webStyle.cardImage}
                    src={require("../../assets/" + item.image)}
                  />
                  <p style={webStyle.cardTag}>{item.tag}</p>
                  <h1 style={webStyle.cardTitle}>{item.title}</h1>
                  <p style={webStyle.cardDescription}>{item.description}</p>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainGrid: {
    width: "100%",
    maxWidth: "1438px",
    margin: "auto",
    display: "flex",
    flexFlow: "row",
    gap: "60px",
    marginBottom: "150px"
  },
  cardGridParent: {
    width: "100%",
    height: "535px"
  },
  cardMainBox: {
    height: "auto",
    borderRadius: "8px",
    backgroundColor: "#F8FAFC",
    padding: "45px 48px"
  },
  cardImage: {
    width: "100%",
    height: "258px",
    objectFit: "contain" as const
  },
  cardTag: {
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "145%",
    letterSpacing: "0.033px",
    fontStyle: "normal",
    color: "#060A14",
    textTransform: "uppercase" as const,
    marginTop: "60px",
    marginBottom: "16px"
  },
  cardTitle: {
    height: "77px",
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "120%",
    fontStyle: "normal",
    color: "#060A14",
    marginBottom: "16px"
  },
  cardDescription: {
    fontWeight: 500,
    fontSize: "17px",
    lineHeight: "145%",
    letterSpacing: "0.043px",
    fontStyle: "normal",
    color: "#060A14"
  }
};
// Customizable Area End
