import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  tabIndex: number;
  isVideoVisible: boolean;
  universityCardHoverIndex: number;
  isOverlayVideoPlaying: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

// Customizable Area Start
interface Body {
  [key: string]: string | number[];
}

interface ApiData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: Body;
  type?: string;
}
// Customizable Area End

export default class StudentPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getStudentApiCallId: string;
  videoSectionRef: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.videoSectionRef = React.createRef();
    this.getStudentApiCallId = "";

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      arrayHolder: [],
      token: "",
      tabIndex: 0,
      isVideoVisible: false,
      universityCardHoverIndex: -1,
      isOverlayVideoPlaying: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  async componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY >= 400) {
      this.setState({ isVideoVisible: true });
    } else {
      this.setState({ isVideoVisible: false });
    }
  };

  handleUniversityCardMouseEnter = (index: number) => {
    this.setState({ universityCardHoverIndex: index });
  };

  handleUniversityCardMouseLeave = (index: number) => {
    this.setState({ universityCardHoverIndex: index });
  };

  handleOverlayVideoPlay = () => {
    this.setState({
      isOverlayVideoPlaying: !this.state.isOverlayVideoPlaying
    });
    console.log("isOverlayVideoPlaying: ", this.state.isOverlayVideoPlaying);
  };
  // Customizable Area End
}

// Customizable Area Start
export const commonWebStyle = {
  leftBlueBackground: {
    position: "absolute" as const,
    width: "268px",
    height: "268px",
    left: "-300px",
    background: "#2F57A5",
    filter: "blur(80px)",
    opacity: ".6",
    top: "-600px"
  },
  rightBlueBackground: {
    position: "absolute" as const,
    width: "268px",
    height: "268px",
    right: "-300px",
    background: "#2F57A5",
    filter: "blur(80px)",
    opacity: ".6",
    top: "-200px"
  },
  rightDoottedBackground: {
    position: "absolute" as const,
    width: "268px",
    height: "268px",
    right: "-300px",
    top: "-100px"
  },
  leftDoottedBackground: {
    position: "absolute" as const,
    width: "268px",
    height: "268px",
    left: "-300px",
    top: "-100px"
  }
};
// Customizable Area End
