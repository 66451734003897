export const appLogo = require("../assets/appLogo.svg");
export const backgroundImgRIghtBottom = require("../assets/backgroundImgRIghtBottom.svg");
export const Facebook = require("../assets/Facebook.svg");
export const Linkedin = require("../assets/Linkedin.svg");
export const Twitter = require("../assets/Twitter.svg");
export const YouTube = require("../assets/YouTube.svg");




