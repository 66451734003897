import React from "react";

import {
    Box,
    Grid
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    Partners,
    whileRIghtArrow,
    overlayBackground
} from "./assets";
import Slider from 'react-slick';
import { Link } from "react-router-dom";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class ImageSliderOverlay extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const settings = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4.5,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1366,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1.45,
                        slidesToScroll: 1,
                    },
                },
            ],
        };
        return (
            // Customizable Area Start
            <>
                <section className="sectionImageSlider sectionPadding" style={{paddingTop:"70px"}}>

                    <div className="imageSliderRow">
                        <div className="imageSliderLeft"></div>
                        <div className="imageSliderRight">
                            <div className="ImageSliderTop sectionHeading videoContentHeader">
                                <Grid container spacing={2}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <Box className="imageSliderFlex" style={{ marginBottom: "32px" }}>
                                            <span className="sectionHeading blueHeading" style={{marginRight: "7px"}}>
                                                We Support Everyone in the Industry
                                            </span>
                                            <span className="sectionHeading blackHeading">
                                                by Being Thought Leaders
                                            </span>
                                        </Box>
                                        <Box>
                                            <p className={"subHeading"}>
                                                We believe that education should be accessible to all.
                                            </p>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{ maxHeight: '410px' }}>
                                <Slider {...settings} >
                                    <div className="slide">
                                        <img src={overlayBackground} alt="Image 1" className="slideImage" />
                                        <div className="overlay">
                                            <Box className="overlayTextImage">
                                                <div className="overlaytextImageInner">
                                                    <Box>
                                                        <p className="overlayDate">Mar 21, 2022</p>
                                                        <h2 className="overlayHeading">Which Countries Will Be the Next Big Destinations for International Students?</h2>
                                                    </Box>
                                                    <Box>
                                                        <h2 className="overlayContent">International student populations are growing in every corner of the world.</h2>
                                                        <div className="Button">
                                                            <Link to="" className="btnWithArrow" href="/">Read More<img src={whileRIghtArrow} alt="" /></Link>                                                    </div>
                                                    </Box>
                                                </div>
                                            </Box>
                                        </div>
                                    </div>
                                    <div className="slide">
                                        <img src={overlayBackground} alt="Image 1" className="slideImage" />
                                        <div className="overlay">
                                            <Box className="overlayTextImage">
                                                <div className="overlaytextImageInner">
                                                    <Box>
                                                        <p className="overlayDate">Feb 11, 2014</p>
                                                        <h2 className="overlayHeading">Why Study Hospitality?</h2>
                                                    </Box>
                                                    <Box>
                                                        <h2 className="overlayContent">International student populations are growing in every corner of the world.</h2>
                                                        <div className="Button">
                                                            <Link to="" className="btnWithArrow" href="/">Read More<img src={whileRIghtArrow} alt="" /></Link>                                                    </div>
                                                    </Box>
                                                </div>
                                            </Box>
                                        </div>
                                    </div>
                                    <div className="slide">
                                        <img src={overlayBackground} alt="Image 1" className="slideImage" />
                                        <div className="overlay">
                                            <Box className="overlayTextImage">
                                                <div className="overlaytextImageInner">
                                                    <Box>
                                                        <p className="overlayDate">Oct 24, 2018</p>
                                                        <h2 className="overlayHeading">5 Reasons for UK Student Visa Rejection</h2>
                                                    </Box>
                                                    <Box>
                                                        <h2 className="overlayContent">International student populations are growing in every corner of the world.</h2>
                                                        <div className="Button">
                                                            <Link to="" className="btnWithArrow" href="/">Read More<img src={whileRIghtArrow} alt="" /></Link>                                                    </div>
                                                    </Box>
                                                </div>
                                            </Box>
                                        </div>
                                    </div>
                                    <div className="slide">
                                        <img src={overlayBackground} alt="Image 1" className="slideImage" />
                                        <div className="overlay">
                                            <Box className="overlayTextImage">
                                                <div className="overlaytextImageInner">
                                                    <Box>
                                                        <p className="overlayDate">Aug 2, 2013</p>
                                                        <h2 className="overlayHeading">Which Countries Will Be the Next Big Destinations for International Students?</h2>
                                                    </Box>
                                                    <Box>
                                                        <h2 className="overlayContent">International student populations are growing in every corner of the world.</h2>
                                                        <div className="Button">
                                                            <Link to="" className="btnWithArrow" href="/">Read More<img src={whileRIghtArrow} alt="" /></Link>
                                                        </div>
                                                    </Box>
                                                </div>
                                            </Box>
                                        </div>
                                    </div>
                                    <div className="slide">
                                        <img src={overlayBackground} alt="Image 1" className="slideImage" />
                                        <div className="overlay">
                                            <Box className="overlayTextImage">
                                                <div className="overlaytextImageInner">
                                                    <Box>
                                                        <p className="overlayDate">Feb 9, 2015</p>
                                                        <h2 className="overlayHeading">Why Study Hospitality?</h2>
                                                    </Box>
                                                    <Box>
                                                        <h2 className="overlayContent">International student populations are growing in every corner of the world.</h2>
                                                        <div className="Button">
                                                            <Link to="" className="btnWithArrow" href="/">Read More<img src={whileRIghtArrow} alt="" /></Link>
                                                        </div>
                                                    </Box>
                                                </div>
                                            </Box>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
