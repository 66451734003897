import React from "react";

import {
    Box,
    Grid
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { LeadershipsliderImg1, LeadershipsliderImg2, LeadershipsliderImg3} from "./assets";
import "./LeadershipPage.css"

import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";


export default class LeadershipSlider extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const membersData = [
            {
                id: 1,
                image: LeadershipsliderImg3,
                name: 'Eleanor Pena',
                position: 'Medical Assistant'
            },
            {
                id: 2,
                image: LeadershipsliderImg2,
                name: 'Marvin McKinney',
                position: 'President of Sales'
            },
            {
                id: 3,
                image: LeadershipsliderImg1,
                name: 'Albert Flores',
                position: 'Nursing Assistant'
            },
            {
                id: 4,
                image: LeadershipsliderImg3,
                name: 'Eleanor Pena',
                position: 'Medical Assistant'
            },
            {
                id: 5,
                image: LeadershipsliderImg2,
                name: 'Eleanor Pena',
                position: 'Medical Assistant'
            },

        ]

        const settings = {
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,

                    }
                },
                {
                    breakpoint: 770,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },

            ]
        };

        return (
            // Customizable Area Start
            <>
                <div className="LeadershipSliderContainer">
                    <div className="containerLeft">
                        <h3>Meet <span style={{ color: '#000000'}}>team</span><br /> Members</h3>
                    </div>
                    <div className="containerRight">
                        <div className="LeadershipSliderContainer">
                            <div className="LeadershipSlideInnerContainer">
                                <Slider {...settings}>
                                    {
                                        membersData.map((el: any, elIndex: any) => (
                                            <div key={elIndex}>
                                                <div className="imageBox">
                                                    <img src={el.image} alt="" />
                                                    <div className="leadershiImageOnText">
                                                        <h3>{el.name}</h3>
                                                        <p>{el.position}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
