import React from "react";

import {
    Container,
    Box,
    Grid
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    BlueRightArrow,
    playIcon2,
    bgDotsSmall,
} from "./assets";
import { Link } from "react-router-dom";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class AboutUsVideoContent extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const videoUrl = "https://player.vimeo.com/external/600284816.sd.mp4?s=6dd6e76611df093168e89c7a222ce3ae7ab7756b&profile_id=164&oauth2_token_id=57447761"
        return (
            // Customizable Area Start
            <div className="AboutUsVideoContent">
                <div className="VideoContentSection">
                    <div className="sectionBackgroundLeft" >
                        <Container style={{ maxWidth: "1500px" }}>
                            <Box className="videoContentBox">
                                <div className="textImageTop sectionHeading videoContentHeader">
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <Box style={{ marginBottom: "32px" }}>
                                                <span className="sectionHeading blackHeading blueHeading">
                                                    What
                                                </span>&nbsp;
                                                <span className="sectionHeading blueHeading"> 
                                                    We Do
                                                </span>
                                            </Box>
                                            <Box>
                                                <p className={"subHeading"}>
                                                    We believe that education should be accessible to all.
                                                </p>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="videoContentBottom sectionPadding">
                                    <div className="twoBoxWrapper pTb100">
                                        <div className="contentBox twoBoxColumn twoBoxColumntext">
                                            <h1>Students</h1>
                                            <p className="videoContentWidth">Using the StoryBoard Platform, students discover programs and apply to study at the educational institutions that best meet their background and interests. Our team of experts provide students with support every step of the way, including reviewing their applications, ensuring all documents are submitted, communicating with the institutions,
                                             assisting with the visa process, and helping them start their journey abroad!
                                            </p>
                                            <div className="Button">
                                                <Link to="" className="blueBtn blueBtnRIghtArrow" href="/">Student Registration<img src={BlueRightArrow} alt="" /></Link>
                                            </div>
                                        </div>
                                        <div className="VideoBox twoBoxColumn">
                                            {this.state.vidFirstPlaying ?
                                                <video
                                                    autoPlay
                                                    loop
                                                    
                                                    controls
                                                >
                                                    <source src={videoUrl} type="video/mp4" />
                                                </video> : null}
                                            {!this.state.vidFirstPlaying ?
                                                <video
                                                    loop
                                                    
                                                >
                                                    <source src={videoUrl} type="video/mp4" />
                                                </video> : null}
                                            {!this.state.vidFirstPlaying ?
                                                <img src={playIcon2} alt="" onClick={() => this.handlePassionateSectionVid('video1')} data-test-id="handle-icon" /> : null}
                                        </div>
                                        <div style={{position:"relative"}}>
                                            <div className="bgDotsSmallimg_right"> <img src={bgDotsSmall} alt="" /></div>
                                        </div>
                                    </div>
                                    
                                    <div className="twoBoxWrapper columnReverse768 pTb100">
                                        <div className="VideoBox twoBoxColumn">
                                            {this.state.vidSecondPlaying ?
                                                <video
                                                    autoPlay
                                                    loop
                                                    controls
                                                >
                                                    <source src={videoUrl} type="video/mp4" />
                                                </video>
                                                : null}

                                            {!this.state.vidSecondPlaying ?
                                                <video
                                                    loop
                                                    
                                                >
                                                    <source src={videoUrl} type="video/mp4" />
                                                </video>
                                                :
                                                null}
                                            {!this.state.vidSecondPlaying ?
                                                <img src={playIcon2} alt="" onClick={() => this.handlePassionateSectionVid('video2')} />
                                                : null}
                                        </div>
                                        <div className="contentBox twoBoxColumn twoBoxColumntext">
                                             <div style={{position:"relative"}}>
                                                <div className="AboutUs_Background_circle_right"></div>
                                            </div>
                                            <h1>Scholarship</h1>
                                            <p className="videoContentWidth">StoryBoard works with 10,000+ Partners to make education accessible to students. Through the StoryBoard Platform, Partners can manage their student applications and information while staying up-to-date on application statuses. StoryBoard submits the student’s application to the university
                                             and provides commissions once the student is enrolled.
                                            </p>
                                            <div className="Button">
                                                <Link to="" className="blueBtn blueBtnRIghtArrow" href="/">Partner Registration<img src={BlueRightArrow} alt="" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="twoBoxWrapper pTb100">
                                        <div className="contentBox twoBoxColumn twoBoxColumntext">
                                            <h1>Universities</h1>
                                            <p className="videoContentWidth">By working with StoryBoard, educational institutions gain access to StoryBoard’s managed network of 10,000+ Partners around the world. StoryBoard actively recruits students from 150+ countries to bring culture and diversity to campuses. StoryBoard reviews all applications before submission, ensuring that students
                                             are qualified and that applications adhere to the university’s guidelines.
                                            </p>
                                            <div className="Button">
                                                <Link to="" className="blueBtn blueBtnRIghtArrow" href="/">Partner Inquiry<img src={BlueRightArrow} alt="" /></Link>
                                            </div>
                                        </div>
                                        <div className="VideoBox twoBoxColumn">
                                            {this.state.vidFirstPlaying ?
                                                <video
                                                    autoPlay
                                                    loop
                                                    
                                                    controls
                                                >
                                                    <source src={videoUrl} type="video/mp4" />
                                                </video> : null}
                                            {!this.state.vidFirstPlaying ?
                                                <video
                                                    loop
                                                    
                                                >
                                                    <source src={videoUrl} type="video/mp4" />
                                                </video> : null}
                                            {!this.state.vidFirstPlaying ?
                                                <img src={playIcon2} alt="" onClick={() => this.handlePassionateSectionVid('video1')} data-test-id="handle-icon" /> : null}
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Container>
                    </div>
                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
