import React from "react";
// Customizable Area Start
import { Box, Grid } from "@material-ui/core";
// Customizable Area End

import StudentPageController, { Props } from "./StudentPageController";

export default class ChooseStudyBoard extends StudentPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ width: "100%", maxWidth: "1334", margin: "0 auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1 style={webStyle.chooseStudyBoardHeading}>
              Why Choose <span style={webStyle.textHighlight}>StudyBoard</span>{" "}
              ?
            </h1>
            <p style={webStyle.chooseStudyBoardSubtext}>
              We believe that education should be accessible to all.
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: "80px" }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            style={webStyle.chooseStudyBoardBlock}
          >
            <img
              style={webStyle.chooseStudyBoardBlockImage}
              src={require("../../assets/admission.svg")}
            />
            <p style={webStyle.chooseStudyBoardBlockText}>
              Improve Your Chance of <br /> Admission Success
            </p>
            <span style={webStyle.chooseStudyBoardBlockSubText}>
              Students who use StudyBoard have a 95% <br /> acceptance rate.
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            style={webStyle.chooseStudyBoardBlock}
          >
            <img
              style={webStyle.chooseStudyBoardBlockImage}
              src={require("../../assets/opportunity.svg")}
            />
            <p style={webStyle.chooseStudyBoardBlockText}>
              More Opportunity at <br /> Less Cost
            </p>
            <span style={webStyle.chooseStudyBoardBlockSubText}>
              You’ll spend half the time and <br /> money to get an offer.
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            style={webStyle.chooseStudyBoardBlock}
          >
            <img
              style={webStyle.chooseStudyBoardBlockImage}
              src={require("../../assets/abroad-shop.svg")}
            />
            <p style={webStyle.chooseStudyBoardBlockText}>
              Your One-Stop Study <br /> Abroad Shop
            </p>
            <span style={webStyle.chooseStudyBoardBlockSubText}>
              Access exclusive deals on application fees, <br /> flights, expert
              advice, and more.
            </span>
          </Grid>
          <Grid item xs={12} style={webStyle.buttonBox}>
            <button style={webStyle.whatWeOfferBtn}>
              Learn more about what we offer{" "}
              <img
                style={{ marginLeft: "8px" }}
                src={require("../../assets/arrow.svg")}
              />
            </button>
          </Grid>
        </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  textHighlight: {
    color: "#2F57A5"
  },
  chooseStudyBoardHeading: {
    fontWeight: 500,
    fontSize: "50px",
    lineHeight: "120%",
    textAlign: "center" as const,
    color: "#060A14",
    marginBottom: "32px",
    marginTop: "150px"
  },
  chooseStudyBoardSubtext: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center" as const,
    letterSpacing: "0.05px",
    color: "#334155",
    marginBottom: "108px"
  },
  chooseStudyBoardBlock: {
    width: "100%",
    textAlign: "center" as const
  },
  chooseStudyBoardBlockImage: {
    width: "94px",
    height: "94px",
    marginBottom: "16px"
  },
  chooseStudyBoardBlockText: {
    fontWeight: 500,
    fontSize: "22px",
    textAlign: "center" as const,
    color: "#060A14",
    marginBottom: "12px"
  },
  chooseStudyBoardBlockSubText: {
    fontWeight: 500,
    fontSize: "17px",
    lineHeight: "145%",
    textAlign: "center" as const,
    letterSpacing: "0.043px",
    color: "#64748B"
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  whatWeOfferBtn: {
    width: "max-content",
    height: "56px",
    padding: "16px 32px",
    background: "#2F57A5",
    border: "1px solid #2F57A5",
    color: "white",
    fontFamily: "ZonaPro",
    fontSize: "17px",
    textTransform: "uppercase" as const,
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "8px",
    marginTop: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  }
};
// Customizable Area End
