import React from "react";
// Customizable Area Start
import { Box, Grid } from "@material-ui/core";
// Customizable Area End

import StudentPageController, { Props } from "./StudentPageController";

export default class VisaCalculator extends StudentPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Grid
          container
          spacing={2}
          style={{
            marginBottom: "80px",
            marginTop: "150px",
            width: "100%",
            maxWidth: "1268px",
            margin: "0 auto"
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={webStyle.visaLeftSection}
          >
            <h1 style={webStyle.visaCalculatorHeading}>
              Visa <span style={webStyle.textHighlight}>Calculator</span>{" "}
            </h1>
            <p style={webStyle.visaCalculatorText}>
              This free tool uses machine learning and historical data <br /> to
              reveal how likely you are to get Canadian study permit <br />{" "}
              approval. Try it out!
            </p>
            <button style={webStyle.calculateBtn}>
              Calculate Your Chances{" "}
              <img
                style={{ marginLeft: "8px" }}
                src={require("../../assets/arrow.svg")}
              />
            </button>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={6} style={webStyle.visaRightSection}>
            <img src={require("../../assets/visa-calculator.svg")} />
          </Grid>
        </Grid>
        <Box style={{ position: "relative" }}>
          <Box
            style={{
              position: "absolute",
              left: "-100px",
              top: "-40px"
            }}
          >
            <img
              style={{ width: "250px" }}
              src={require("../../assets/dot-bg.svg")}
            />
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  textHighlight: {
    color: "#2F57A5"
  },
  visaLeftSection: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  visaRightSection: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "flex-end"
  },
  visaCalculatorHeading: {
    fontWeight: 500,
    fontSize: "50px",
    lineHeight: "120%",
    textAlign: "center" as const,
    color: "#060A14",
    marginBottom: "32px"
  },
  visaCalculatorText: {
    fontWeight: 500,
    fontSize: "17px",
    lineHeight: "200%",
    letterSpacing: "0.043px",
    color: "#64748B"
  },
  calculateBtn: {
    width: "max-content",
    height: "56px",
    padding: "8px 32px",
    background: "#2F57A5",
    border: "1px solid #2F57A5",
    color: "white",
    fontFamily: "ZonaPro",
    fontSize: "17px",
    textTransform: "uppercase" as const,
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "8px",
    marginTop: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  visaCalculatorImage: {
    width: "400px"
  }
};
// Customizable Area End
