import React from "react";

import {
  Container,
  Box
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { BlueRightArrow, bgDotsSmall, playIconLarge } from "./assets";
import { Link } from "react-router-dom";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class AboutUsVideoOverlay extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="sectionVideoOverlay" style={{marginBottom: "80px",position:"relative"}}>
        <div style={{ position: 'absolute', bottom: '-67px', right: '107px'}}>
          <img src={bgDotsSmall} alt="#"/>
        </div>
        <Container style={{ maxWidth: "1500px" }}>
          <div className="VideoOverlayBox">
            {!this.state.isOverlayVideoPlaying ?
              <video
                loop
              >
                <source src="https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4" type="video/mp4" />
              </video>
              :
              null}
            {this.state.isOverlayVideoPlaying ?
              < video
                loop
                autoPlay
                controls
              >
                <source src="https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4" type="video/mp4" />
              </video>
              :
              null}

            <div className="videoOverlayText" style={{ display: this.state.isOverlayVideoPlaying ? 'none' : 'block' }}>
              <div className={"iconbox"}>
                <img src={playIconLarge} alt="" onClick={this.handleOverlayVideoPlay} />
              </div>
              <div className="videoOverlayTextInner">
                <span className="blueHeading">How we </span>
                <span className="blackHeading">help</span>
                <p>We believe that education should be accessible to all.</p>
                <Box className="">
                  <div className="Button">
                    <Link to="" className="blueBtn blueBtnRIghtArrow" href="/" style={{ margin: "0 auto" }}>Partner with us<img src={BlueRightArrow} alt="" /></Link>
                  </div>
                </Box>
              </div>
            </div>
          </div>
          {this.state.isOverlayVideoPlaying &&
            <Box className="overlayVideoLink">
              <div className="Button">
                <Link to="" className="blueBtn blueBtnRIghtArrow" href="/" style={{ margin: "0 auto" }}>Partner with us<img src={BlueRightArrow} alt="" /></Link>
              </div>
            </Box>
          }
        </Container >
      </div >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
