import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    AboutUsStepper_IMG1, AboutUsStepper_IMG2, AboutUsStepper_IMG3, AboutUsStepper_IMG4, AboutUsStepper_IMG5, AboutUsStepper_IMG6
} from "./assets";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class AboutUsSteeper extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  steps = [
    {
        id:1,
        text: "Helping Students Achieve Success",
        Image: AboutUsStepper_IMG1,
        isActive:false
    },
    {
        id:2,
        text: "Caring About Each Other",
        Image: AboutUsStepper_IMG2,
        isActive:false

    },
    {
        id:3,
        text: "Delivering A+ Customer Experience",
        Image: AboutUsStepper_IMG3,
    },
    {
        id:4,
        text: "Taking Ownership",
        Image: AboutUsStepper_IMG4,
    },
    {
        id:5,
        text: "Innovating and Improving",
        Image: AboutUsStepper_IMG5,
    },
    {
        id:6,
        text: "Making Work Fun",
        Image: AboutUsStepper_IMG6,
    },
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div className="sectionStudentHelp sectionTextImage sectionPadding">
          <Container style={{ maxWidth: "1500px",maxHeight:"778px" }}>
            <div className="textImageTop sectionHeading">
              <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={12}>
                  <Box style={{ marginBottom: "32px" }}>
                    <h1 className="sectionHeading blackHeading">
                    At StoryBoard We're
                    </h1>
                    <h1 className="sectionHeading blueHeading">
                    <span style={{color:"#060A14"}} > Committed to</span> Our Values
                    </h1>
                    
                  </Box>
                  <Box>
                    <p className={"subHeading"}>
                      We believe that education should be accessible to all.
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <div className="textImageBottom">
              <div className="textImageBottomInner">
                <Container style={{
                    maxWidth: "inherit",
                    paddingLeft: "0px",
                    paddingRight:"0"
                }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <div style={{maxWidth:"320px",height:"100%"}}>
                            {this.steps.map((step,index)=>(
                                <div key={step.id} className="TextMain" style={{background: this.state.activeIndex===index? '#EBF0F9':"#fff"}}>
                                    <div style={{display:"flex",margin:"15px"}}> 
                                        <span className="sequenceNumber" style={{
                                            background: this.state.activeIndex===index?'#2F57A5':"#EBF0F9",
                                            color: this.state.activeIndex===index?'#ffffff':"#334155",
                                        }}>{step.id}</span> 
                                        <span style={{color:"#334155"}} >{step.text}</span> 
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6} style={{marginBottom: "80px"}}>
                        {/* <div className="textImageRight">
                            <img src={AboutUsSteeperImg} alt="" />
                        </div> */}
                          {this.steps.map((step, index) => (
                            this.state.activeIndex === index ? (
                              <div key={step.id} className="textImageRight">
                                <img src={step.Image} alt=""/>
                              </div>
                            ) : null
                          ))}
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </Container>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
