import React from "react";

import {
  Box,
  Grid
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { MapImg, whileRIghtArrow } from "./assets";
import { Link } from "react-router-dom";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class Map extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="sectionMap sectionPadding" style={{paddingTop:"70px"}}>
        {/* <Container style={{ maxWidth: "1500px" }}> */}
        <div className="mapSectionRow">
          <div className="mapSectionLeftColumn"></div>
          <div className="mapSectionRightColumn">
          <div className="mapSection">
            <div className="mapSectionLeft">
              <div className="mapSectionLeftInner">
                <Grid container spacing={2}>
                  <Grid item sm={12} md={12} lg={12}>
                    <Box className="mobileTextCenter responsiveMapContent" style={{ marginBottom: "32px" }}>
                      <span className="sectionHeading blackHeading">
                        We connect&nbsp;
                      </span>
                      <span className="sectionHeading blueHeading">
                        University, Students&nbsp;
                      </span>
                      <span className="sectionHeading blackHeading">
                        and&nbsp;
                      </span>
                      <span className="sectionHeading blueHeading">
                        Partners
                        <br />
                      </span>
                      <span className="sectionHeading blackHeading">
                        from every part of the world
                      </span>
                    </Box>
                    <Box className="mapContent">
                      <p
                        className={"subHeading mobileTextCenter"}
                        style={{ textAlign: "start" }}
                      >
                        We are always availed to consult you on taking your
                        higher education to the next level in the competitive
                        world.
                      </p>
                    </Box>
                    <div
                      className="mapDashBoardcount"
                    >
                      <Box>
                        <Box className="dashboradCountBox">
                            <div className="dashboradCount mobileTextCenter">
                              <h2>600,000 +</h2>
                              <p>Students helped</p>
                            </div>
                            <div className="dashboradCount mobileTextCenter">
                              <h2>150,000 +</h2>
                              <p>Programs</p>
                            </div>
                            <div className="dashboradCount mobileTextCenter">
                              <h2>20,000 +</h2>
                              <p>Partners</p>
                            </div>
                        </Box>
                      </Box>
                    </div>
                    <div className="mapLink" style={{fontFamily:"ZonaPro"}}>
                      <Link to="" className="blueBtn">
                        Join Us <img src={whileRIghtArrow} alt="" />
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="mapSectionRight">
              <div className="mapSectionRightInner mobileDNone">
                <img src={MapImg} alt="" />
              </div>
            </div>
          </div>
          </div>
        </div>
        {/* </Container> */}
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
