import React from "react";
// Customizable Area Start
import "./UniversityPage.css";
import TopSection from "./TopSection.web";
import ThreeCardSection from "./ThreeCardSection.web";
import PartnerSuccessStories from "./PartnerSuccessStories.web";
import FourVerticalCardSection from "./FourVerticalCardSection.web";
import FiveStepsSection from "./FiveStepsSection.web";
import UniversityLogosSection from "./UniversityLogosSection.web";
import FeaturesWithImage from "./FeaturesWithImage.web";
import InsightCards from "./InsightCards.web";
import ReviewsSection from "./ReviewsSection.web";
import FormSection from "./FormSection.web";
import ApplyNow from "./ApplyNow.web";
// Customizable Area End

import UniversityPageController, { Props } from "./UniversityPageController";

export default class UniversityPage extends UniversityPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div
        className="universityPage"
        style={webStyle.UniversityPage}
        onScroll={this.handleScroll}
      >
        <TopSection />
        <ThreeCardSection />
        <PartnerSuccessStories />
        <FourVerticalCardSection />
        <FiveStepsSection />
        <UniversityLogosSection />
        <FeaturesWithImage />
        <InsightCards />
        <ReviewsSection />
        <FormSection />
        <ApplyNow />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  UniversityPage: {
    width: "100%",
    overflow: "hidden"
  }
};
// Customizable Area End
