import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import TopSection from "./TopSection.web";
import StudyAbroadSuccess from "./StudyAbroadSuccess.web";
import VisaCalculator from "./VisaCalculator.web";
import StudyAbroadDreams from "./StudyAbroadDreams.web";
import PopularFieldsofStudy from "./PopularFieldsofStudy.web";
import StudyAbroadDestination from "./StudyAbroadDestination.web";
import ChooseStudyBoard from "./ChooseStudyBoard.web";
import ExploreUniversities from "./ExploreUniversities.web";
import ApplyNow from "./ApplyNow.web";
import StudentSuccessStories from "./StudentSuccessStories.web";
import "./StudentPage.css";
// Customizable Area End

import StudentPageController, { Props } from "./StudentPageController";

export default class StudentPage extends StudentPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box
        style={webStyle.StudentPage}
        onScroll={this.handleScroll}
        className="studentPage"
      >
        <TopSection />
        <StudyAbroadSuccess />
        <VisaCalculator />
        <PopularFieldsofStudy />
        <StudyAbroadDestination />
        <StudyAbroadDreams />
        <ExploreUniversities />
        <StudentSuccessStories />
        <ChooseStudyBoard />
        <ApplyNow />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  StudentPage: {
    overflow: "hidden"
  }
};
// Customizable Area End
