import React from "react";

import {
    Box,
    Grid,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    homeBanner,
    MobileBannerSubtract,
    StudentBtnIcon,
    PartnersBtnIcon,
    UniversityBtnIcon,
    flag1,
    flag2,
    flag3,
    flag4,
    flag5,
    flag6,
} from "./assets";
import { Link } from "react-router-dom";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class LandingPageBanner extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <div className="sectionLandingPageBanner">
                    <div className="landingPageBannerBox">
                        <div className="bannerVideo">
                            <video
                                src="https://player.vimeo.com/external/369832451.sd.mp4?s=d32258168993aac7c6772aabb0af0fd65a507599&profile_id=164&oauth2_token_id=57447761"
                                autoPlay
                                loop
                                muted
                            />
                        </div>
                        <div className="bannerImageOverlay">
                            <img className="mobileDNone" src={homeBanner} alt="" />
                            <img className="mobileDBlock dNone" src={MobileBannerSubtract} alt="" />
                        </div>
                        <BannerContent/>
                    </div>
                    <BannerContent/>
                </div >
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const BannerContent = () => {
    return(
        <div className="bannerOvverlayContent bannerOverlayContentMobile">
                            <div className="ImageSliderTop sectionHeading videoContentHeader">
                                <Grid container spacing={2}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <Box className="landingPageBannerHeading">
                                            <span className="sectionHeading bannerHeading blackHeading">
                                                We are on a Mission to literate
                                            </span> &nbsp;
                                            <span className="sectionHeading bannerHeading blueHeading">
                                                the Globe
                                            </span>
                                        </Box>
                                        <Box className="landingPageBannerContent">
                                            <p className={"subHeading"}>
                                                We are always availed to consult you on taking your higher education
                                                to the next level in the competitive world.
                                            </p>
                                        </Box>
                                        <Box className="landingPageBannerBtn">
                                            <h6 className="">join as a</h6>
                                            <Box className="landingPageBannerBtnInner">
                                                <div className="Button">
                                                    <Link to="" className="bannerBtn yellowBtn" href="/"><img src={StudentBtnIcon} alt="" />Student</Link>
                                                </div>
                                                <div className="Button">
                                                    <Link to="" className="bannerBtn greenBtn" href="/"><img src={PartnersBtnIcon} alt="" />Partner</Link>
                                                </div>
                                                <div className="Button">
                                                    <Link to="" className="bannerBtn DarBlueBtn" href="/"><img src={UniversityBtnIcon} alt="" />Universities</Link>
                                                </div>
                                            </Box>
                                            <Box className="landingPageBannerCountryFlag mobileDNone">
                                                <div>
                                                    <Box className="bannerFlags">
                                                        <img src={flag1} alt="" />
                                                        <img src={flag2} alt="" />
                                                        <img src={flag3} alt="" />
                                                        <img src={flag4} alt="" />
                                                        <img src={flag5} alt="" />
                                                        <img src={flag6} alt="" />
                                                        <span>108+ Top Countries around World</span>
                                                    </Box>
                                                </div>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
    )
}
// Customizable Area End
