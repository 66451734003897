import React from "react";

import {
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
ChatBotIcon
} from "./assets";
import { Link } from "react-router-dom";
import './ChatBot.web.css';
// Customizable Area End

import ChatbotController, {
  Props,
} from "./ChatbotController";

export default class Chatbot extends ChatbotController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <Box className='ChatBot'>
        <div className='ChatBot'>
          <Link to=""><img src={ChatBotIcon} alt="" /></Link>
        </div>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Star
// Customizable Area End
