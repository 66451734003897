import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Customizable Area End

import UniversityPageController, { Props } from "./UniversityPageController";

export default class FiveStepsSection extends UniversityPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  sliderSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
          dots: false
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  studentSteps = [
    {
      step: "01",
      title: "Students Check Their Eligibility",
      description:
        "Students complete a short survey and get matched to programs and universitys.",
      icon: "student.png"
    },
    {
      step: "02",
      title: "Students Apply to University",
      description:
        "Students select a university and program, complete their profile, pay their fees, and submit the proper documentation.",
      icon: "apply.png"
    },
    {
      step: "03",
      title: "Students Get Accepted",
      description:
        "The application is reviewed by the university and a letter of acceptance is issued.",
      icon: "letter.png"
    },
    {
      step: "04",
      title: "Student Applies for Visa",
      description:
        "StudyBoard’s experts guide the student through the visa application process.",
      icon: "visa.png"
    },
    {
      step: "05",
      title: "Student Journey Begins",
      description:
        "The student sets off with bags packed ready to start their adventure on your campus.",
      icon: "map.png"
    }
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Box
          style={{ marginTop: "150px", marginLeft: "9%", maxHeight: "650px" }}
        >
          <Box style={{ paddingLeft: "15px" }}>
            <h1 style={webStyle.heading}>
              How It <span style={webStyle.textHighlight}>Works</span>{" "}
            </h1>
            <p style={webStyle.subText}>
              Attract the best students from around the world with StudyBoard.
            </p>
          </Box>
          <Slider {...this.sliderSettings}>
            {this.studentSteps.map((element, index) => (
              <Box key={index}>
                <Box style={webStyle.studentStepBlock}>
                  <Box style={webStyle.studentStepIconNumber}>
                    <Box>
                      <img
                        style={webStyle.studentStepIcon}
                        src={require("../../assets/" + element.icon)}
                      />
                    </Box>
                    <Box>
                      <p style={webStyle.studentStepNumber}>{element.step}</p>
                    </Box>
                  </Box>
                  <p style={webStyle.studentStepTitle}>{element.title}</p>
                  <p style={webStyle.studentStepDescription}>
                    {element.description}
                  </p>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainBox: {
    width: "100%",
    maxWidth: "1400px",
    margin: "0 auto"
  },
  textHighlight: {
    color: "#2F57A5"
  },
  heading: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "50px",
    lineHeight: "120%",
    textAlign: "left" as const,
    color: "#060A14",
    marginBottom: "32px"
  },
  subText: {
    width: "554px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.05px",
    textAlign: "left" as const,
    color: "#334155",
    marginBottom: "48px"
  },
  studentStepBlock: {
    height: "auto",
    backgroundColor: "#FFFFFF",
    border: "1px solid #EBF0F9",
    borderRadius: "8px",
    margin: "16px",
    padding: "60px 40px"
  },
  studentStepIconNumber: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    marginBottom: "32px"
  },
  studentStepIcon: {
    width: "68px"
  },
  studentStepNumber: {
    fontFamily: "Gilroy-Heavy",
    fontWeight: 800,
    fontSize: "66px",
    lineHeight: "120%",
    color: "transparent",
    WebkitTextStrokeWidth: "2px",
    WebkitTextStrokeColor: "#CBD5E1"
  },
  studentStepTitle: {
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "120%",
    color: "#060A14"
  },
  studentStepDescription: {
    height: "100px",
    fontWeight: 500,
    fontSize: "17px",
    lineHeight: "145%",
    color: "#64748B",
    fontFamily: "Gilroy",
    letterSpacing: "0.043px",
    marginTop: "16px"
  }
};
// Customizable Area End
