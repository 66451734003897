import React from "react";
// Customizable Area Start
import { Button, Box } from "@material-ui/core";
// Customizable Area End

import UniversityPageController, {
  Props,
  commonWebStyle
} from "./UniversityPageController";

export default class UniversityLogosSection extends UniversityPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  universitiesList = [
    {
      name: "",
      logo: "university1.svg",
      logoStyle: {
        position: "relative" as const,
        left: "0px",
        top: "80px"
      }
    },
    {
      name: "",
      logo: "university2.svg",
      logoStyle: {
        position: "relative" as const,
        top: "0px"
      }
    },
    {
      name: "",
      logo: "university3.svg",
      logoStyle: {
        position: "relative" as const,
        top: "-15px"
      }
    },
    {
      name: "",
      logo: "university4.svg",
      logoStyle: {
        position: "relative" as const,
        right: "0px",
        top: "15px"
      }
    },
    {
      name: "",
      logo: "university5.svg",
      logoStyle: {
        position: "relative" as const,
        left: "0px"
      }
    },
    {
      name: "",
      logo: "university6.svg",
      logoStyle: {
        position: "relative" as const,
        bottom: "-60px"
      }
    },
    {
      name: "",
      logo: "university7.svg",
      logoStyle: {
        position: "relative" as const,
        bottom: "0px"
      }
    },
    {
      name: "",
      logo: "university8.svg",
      logoStyle: {
        position: "relative" as const,
        bottom: "-60px"
      }
    },
    {
      name: "",
      logo: "university9.svg",
      logoStyle: {
        position: "relative" as const,
        right: "-60px",
        top: "-100px"
      }
    }
  ];

  countriesList = [
    {
      name: "INDIA",
      icon: "india.svg",
      universities: this.universitiesList
    },
    {
      name: "CANADA",
      icon: "canada.svg",
      universities: this.universitiesList
    },
    {
      name: "UNITED STATES",
      icon: "usa.svg",
      universities: this.universitiesList
    },
    {
      name: "UNITED KINGDOM",
      icon: "uk.svg",
      universities: this.universitiesList
    },
    {
      name: "AUSTRALIA",
      icon: "australia.svg",
      universities: this.universitiesList
    },
    {
      name: "NEW ZEALAND",
      icon: "newzealand.svg",
      universities: this.universitiesList
    }
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ width: "100%", marginTop: "150px" }}>
        <Box style={{ position: "relative", top: "100px", right: "100px" }}>
          <Box style={commonWebStyle.rightBlueBackground} />
        </Box>
        <Box style={webStyle.mainBox}>
          <Box style={webStyle.logosOnTop}>
            {this.universitiesList.slice(0, 4).map((image, index) => (
              <img
                style={image.logoStyle}
                key={index}
                src={require("../../assets/" + image.logo)}
              />
            ))}
          </Box>
          <h1 style={webStyle.heading}>
            We Connect You to the Right <br />{" "}
            <span style={webStyle.textHighlight}>University</span> for Your{" "}
            <span style={webStyle.textHighlight}>Students</span>
          </h1>
          <Box style={webStyle.countryButtonBox}>
            {this.countriesList.map((element, index) => (
              <Button
                onClick={() => this.handleSelectCountry(index)}
                style={
                  this.state.selectedCountryIndex === index
                    ? webStyle.countryButtonActive
                    : webStyle.countryButton
                }
                key={index}
              >
                <img
                  style={webStyle.countryIcon}
                  src={require("../../assets/" + element.icon)}
                  alt=""
                />{" "}
                {element.name}
              </Button>
            ))}
          </Box>
          <Box style={webStyle.logosInBottom}>
            {this.universitiesList.slice(4, 9).map((image, index) => (
              <img
                style={image.logoStyle}
                key={index}
                src={require("../../assets/" + image.logo)}
              />
            ))}
          </Box>
          <Box style={webStyle.buttonBox}>
            <Button style={webStyle.exploreInstitutionsButton}>
              Explore Institutions
              <img
                style={{ marginLeft: "8px" }}
                src={require("../../assets/arrow.svg")}
              />
            </Button>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainBox: {
    width: "100%",
    maxWidth: "1438px",
    margin: "0 auto"
  },
  textHighlight: {
    color: "#2F57A5"
  },
  heading: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "50px",
    lineHeight: "120%",
    textAlign: "center" as const,
    color: "#060A14",
    marginBottom: "32px"
  },
  countryButtonBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "16px"
  },
  countryButton: {
    padding: "12px 20px",
    borderRadius: "80px",
    border: "1px solid #EBF0F9",
    background: "#FFF",
    fontSize: "17px",
    fontFamily: "Gilroy",
    fontWeight: 500,
    lineHeight: "145%",
    letterSpacing: "0.043px"
  },
  countryButtonActive: {
    padding: "12px 20px",
    borderRadius: "80px",
    border: "1px solid #EBF0F9",
    background: "#2F57A5",
    fontSize: "17px",
    fontFamily: "Gilroy",
    fontWeight: 500,
    lineHeight: "145%",
    letterSpacing: "0.043px",
    color: "#FFF"
  },
  countryIcon: {
    width: "28px",
    height: "28px",
    borderRadius: "50%",
    marginRight: "12px"
  },
  logosOnTop: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "60px"
  },
  logosInBottom: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "100px"
  },
  buttonBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "75px"
  },
  exploreInstitutionsButton: {
    padding: "16px 32px",
    width: "max-content",
    height: "56px",
    background: "#2F57A5",
    border: "1px solid #2F57A5",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontFamily: "ZonaPro",
    fontWeight: 700 as const,
    fontSize: "17px",
    lineHeight: "24px",
    textAlign: "center" as const,
    textTransform: "uppercase" as const,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "41px",
    cursor: "pointer"
  }
};
// Customizable Area End
