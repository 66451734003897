import React from "react";

import {
    Box,
    Grid
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./LeadershipPage.css"
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class LeadershipTopSection extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Box style={webStyle.topSectionRoot}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h1 style={webStyle.topSectionHeadingNew}>
                                The Leadership{" "}
                                <span style={webStyle.textHighlight}>Team</span> 
                            </h1>
                            <p style={webStyle.topSectionSubTextNew}>
                            Meet the co-founders and leaders of StudyBoard:Meti, <br /> Martin, and Massi Basiri.
                            </p>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Box style={webStyle.elipseImg}>
                                <video
                                    style={
                                        this.state.isVideoVisible
                                            ? webStyle.fullElipseVideoSection
                                            : webStyle.elipseVideo
                                    }
                                    src="https://www.shutterstock.com/shutterstock/videos/1083186550/preview/stock-footage-a-group-of-asian-high-school-students-uses-laptops-to-program-a-renewable-energy-project-for-a.webm"
                                    autoPlay
                                    loop
                                    muted
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    topSectionRoot: {
        width: "100%",
        maxWidth: "1280px",
        margin: "0 auto"
    },
    topSectionHeadingNew: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "66px",
        lineHeight: "120%",
        textAlign: "center" as const,
        color: "#060A14",
        marginBottom: "32px",
        marginTop: "150px"
    },
    topSectionSubTextNew: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "38px",
        textAlign: "center" as const,
        letterSpacing: "0.25px",
        color: "#334155"
    },
    textHighlight: {
        color: "#2F57A5"
    },
    elipseImg: {
        width: "1280",
        height: "640px",
        borderTopLeftRadius: "800px",
        borderTopRightRadius: "800px",
        borderBottom: "0",
        marginTop: "50px",
        marginBottom: "80px"
    },
    elipseVideo: {
        width: "100%",
        borderTopLeftRadius: "800px",
        borderTopRightRadius: "800px"
    },
    fullElipseVideoSection: {
        width: "100vw",
        height: "700px",
        objectFit: "cover" as const,
        position: "absolute" as const,
        left: "0px",
        transition: "all 0.5s ease-in-out 0s"
    }
};
// Customizable Area End
