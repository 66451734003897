import React from "react";
// Customizable Area Start
import { Box, Grid } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Customizable Area End

import StudentPageController, { Props } from "./StudentPageController";

export default class StudyAbroadDestination extends StudentPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          dots: false
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  destinations = [
    {
      title: "India",
      subText: "Study in India",
      image: "india.jpeg"
    },
    {
      title: "Canada",
      subText: "Study in Canada",
      image: "canada.jpeg"
    },
    {
      title: "UK",
      subText: "Study in UK",
      image: "uk.jpeg"
    },
    {
      title: "USA",
      subText: "Study in USA",
      image: "usa.jpeg"
    }
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box style={{ width: "100%", position: "absolute" }}>
          <img
            style={{
              width: "250px",
              position: "relative",
              top: "150px",
              left: "30px"
            }}
            src={require("../../assets/dot-bg.svg")}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1 style={webStyle.studyAbroadDestinationHeading}>
              Choose Your{" "}
              <span style={webStyle.textHighlight}>Study Abroad</span>
              <br />
              Destination
            </h1>
          </Grid>
        </Grid>
        <Box style={webStyle.studyAbroadDestinationContainer}>
          <Slider {...this.settings}>
            {this.destinations.map((element, index) => (
              <Box key={index}>
                <Box style={webStyle.destinationCardGrid}>
                  <Box style={webStyle.destinationCard}>
                    <Box style={webStyle.cardImageDiv}>
                      <img
                        style={webStyle.cardImage}
                        src={require("../../assets/" + element.image)}
                      />
                    </Box>
                    <Box style={webStyle.imageGradient} />
                    <Box style={webStyle.cardContent}>
                      <h1 style={webStyle.countryTitle}>{element.title}</h1>
                      <p style={webStyle.destinationCardText}>
                        {element.subText}
                      </p>
                      <a style={webStyle.destinationCardButton}>
                        Explore more{" "}
                        <img
                          style={{ marginLeft: "8px" }}
                          src={require("../../assets/arrow.svg")}
                        />
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  textHighlight: {
    color: "#2F57A5"
  },
  studyAbroadDestinationHeading: {
    fontWeight: 500,
    fontSize: "50px",
    lineHeight: "120%",
    textAlign: "center" as const,
    color: "#060A14",
    marginBottom: "100px"
  },
  studyAbroadDestinationContainer: {
    marginBottom: "100px",
    maxHeight: "600px",
    marginLeft: "9%"
  },
  destinationCardGrid: {
    padding: "5px",
    width: "95%"
  },
  destinationCard: {
    width: "100%",
    height: "535px",
    borderRadius: "8px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.522) 0%, rgba(0, 0, 0, 0) 100%)"
  },
  imageGradient: {
    width: "100%",
    height: "535px",
    borderRadius: "8px",
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.522) 0%, rgba(0, 0, 0, 0) 100%)",
    position: "relative" as const,
    bottom: "535px"
  },
  cardImageDiv: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    position: "relative" as const
  },
  cardContent: {
    width: "100%",
    height: "100%",
    position: "relative" as const,
    bottom: "1070px"
  },
  cardImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover" as const,
    borderRadius: "8px"
  },
  countryTitle: {
    fontFamily: "Gilroy-Heavy",
    fontWeight: 800,
    fontSize: "86px",
    lineHeight: "80px",
    textAlign: "right" as const,
    letterSpacing: "1px",
    textTransform: "uppercase" as const,
    color: "transparent",
    WebkitTextStrokeWidth: "1px",
    WebkitTextStrokeColor: "#FFFFFF",
    marginRight: "-10px",
    overflow: "hidden",
    paddingTop: "56px"
  },
  destinationCardText: {
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "120%",
    textAlign: "left" as const,
    color: "#FFFFFF",
    marginTop: "296px",
    paddingLeft: "24px"
  },
  destinationCardButton: {
    fontFamily: "ZonaPro",
    fontWeight: 700,
    fontSize: "17px",
    lineHeight: "24px",
    textAlign: "left" as const,
    color: "#FFFFFF",
    paddingLeft: "24px",
    window: "max-content",
    cursor: "pointer",
    display: "flex",
    marginTop: "8px"
  }
};
// Customizable Area End
