import React from "react";
// Customizable Area Start
import { Box, Grid } from "@material-ui/core";
// Customizable Area End

import UniversityPageController, { Props } from "./UniversityPageController";

export default class TopSection extends UniversityPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={{ width: "100%" }}>
        <Box style={webStyle.topSectionContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h1 style={webStyle.topSectionHeading}>
                Be Seen by{" "}
                <span style={webStyle.textHighlight}>100,000+ Students</span>{" "}
                <br /> Every Month
              </h1>
              <p style={webStyle.topSectionSubText}>
                Increase your global presence and the number of qualified
                students <br />
                from a single, easy-to-use platform trusted by <br />
                more than 1,750 institutions worldwide.
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Box style={webStyle.elipseImage}>
                <video
                  style={
                    this.state.isVideoVisible
                      ? webStyle.fullElipseVideo
                      : webStyle.elipseVideo
                  }
                  src="https://www.shutterstock.com/shutterstock/videos/1083186550/preview/stock-footage-a-group-of-asian-high-school-students-uses-laptops-to-program-a-renewable-energy-project-for-a.webm"
                  autoPlay
                  loop
                  muted
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Box style={webStyle.mainStatCard}>
                <Box sx={webStyle.innerStatGrid}>
                  <Box style={webStyle.statisticTitle}>
                    <p style={webStyle.statisticFigure}>600,000 +</p>
                    <p>Students helped</p>
                  </Box>
                  <Box style={webStyle.statisticTitle}>
                    <p style={webStyle.statisticFigure}>150,000 +</p>
                    <p>Programs</p>
                  </Box>
                  <Box style={webStyle.statisticTitle}>
                    <p style={webStyle.statisticFigure}>20,000 +</p>
                    <p>Partners</p>
                  </Box>
                  <Box style={webStyle.statisticTitle}>
                    <p style={webStyle.statisticFigure}>1750 +</p>
                    <p>Universities</p>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  topSectionContainer: {
    width: "100%",
    maxWidth: "1267px",
    margin: "auto"
  },
  topSectionHeading: {
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "66px",
    lineHeight: "120%",
    textAlign: "center" as const,
    color: "#060A14",
    marginBottom: "32px",
    marginTop: "150px"
  },
  topSectionSubText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center" as const,
    letterSpacing: "0.05px",
    color: "#334155"
  },
  textHighlight: {
    color: "#2F57A5"
  },
  elipseImage: {
    width: "1281px",
    height: "640px",
    borderTopLeftRadius: "800px",
    borderTopRightRadius: "800px",
    borderBottom: "0",
    marginTop: "67px"
    // marginBottom: "100px"
  },
  elipseVideo: {
    width: "100%",
    borderTopLeftRadius: "800px",
    borderTopRightRadius: "800px"
  },
  fullElipseVideo: {
    width: "100vw",
    height: "700px",
    objectFit: "cover" as const,
    position: "absolute" as const,
    left: "0px",
    transition: "all 0.5s ease-in-out 0s"
  },
  mainStatCard: {
    padding: "60px",
    width: "1280px",
    background: "#FFFFFF",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "24px",
    marginBottom: "137px",
    marginTop: "100px"
  },
  searchFormFields: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: "8px"
  },
  inputField: {
    padding: "10px 8px 10px 24px",
    gap: "8px",
    width: "100%",
    height: "40px",
    background: "#FFFFFF",
    border: "1px solid #E2E8F0",
    borderRadius: "8px"
  },
  searchBtn: {
    width: "100%",
    height: "40px",
    background: "#2F57A5",
    border: "1px solid #2F57A5",
    color: "white",
    fontSize: "10px",
    textTransform: "uppercase" as const,
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "8px"
  },
  innerStatGrid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    textAlign: "center",
    gap: "8px"
  },
  statisticTitle: {
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "30px",
    fontStyle: "normal",
    textTransform: "uppercase" as const
  },
  statisticFigure: {
    marginBottom: "12px",
    fontSize: "26px"
  }
};
// Customizable Area End
