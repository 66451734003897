import React from "react";
// Customizable Area Start
import { Grid, Box, Typography, Button } from "@material-ui/core";
// Customizable Area End

import UniversityPageController, {
  Props,
  commonWebStyle
} from "./UniversityPageController";

export default class ThreeCardSection extends UniversityPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  cards = [
    {
      title: "Quality Applications",
      description:
        "For every hundred students, we get fifteen more enrolled than others.",
      tag: "Conversion",
      image: "card-img1.svg"
    },
    {
      title: "Unmatched Diversity",
      description: "StudyBoard students come from more than 150 countries.",
      tag: "Diversity",
      image: "card-img2.svg"
    },
    {
      title: "Less Administration. More Education",
      description: "StudyBoard students come from more than 150 countries.",
      tag: "Productivity / ROI",
      image: "card-img3.svg"
    }
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.mainBox}>
        <Grid container spacing={2} style={webStyle.verticalGrid}>
          <Grid item lg={4} md={4} xs={12}>
            <Box style={webStyle.contentContainer}>
              <Typography>
                <Box style={webStyle.heading}>
                  Expanding Our <br />{" "}
                  <span style={webStyle.textHighlight}> International </span>{" "}
                  Reach
                </Box>
                <Box style={webStyle.subText}>
                  Attract the best students from around the world with
                  StudyBoard.
                </Box>
                <Box>
                  <Button style={webStyle.getStartedButton}>
                    Let's get started
                    <img
                      style={{ marginLeft: "8px" }}
                      src={require("../../assets/arrow-blue.svg")}
                    />
                  </Button>
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <Grid
              container
              spacing={2}
              style={{ paddingTop: "164px", paddingLeft: "86px" }}
            >
              <Grid xs={12}>
                <Box className="hexagon">
                  <Box className="hexagon-inner" />
                  <img
                    style={webStyle.cardImage}
                    src={require("../../assets/vertical-card1.svg")}
                  />
                </Box>
                <Box style={webStyle.textBox}>
                  <p
                    style={{ ...webStyle.cardText, ...webStyle.textWithMargin }}
                  >
                    Increase Student Diversity
                  </p>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="hexagon">
                  <Box className="hexagon-inner" />
                  <img
                    style={webStyle.cardImage}
                    src={require("../../assets/vertical-card2.svg")}
                  />
                </Box>
                <Box style={webStyle.textBox}>
                  <p style={webStyle.cardText}>Approved Partner Network</p>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <Grid
              container
              spacing={2}
              style={{ position: "relative", right: "-90px" }}
            >
              <Grid xs={12}>
                <Box className="hexagon">
                  <Box className="hexagon-inner" />
                  <img
                    style={webStyle.cardImage}
                    src={require("../../assets/vertical-card3.svg")}
                  />
                </Box>
                <Box style={webStyle.textBox}>
                  <p
                    style={{ ...webStyle.cardText, ...webStyle.textWithMargin }}
                  >
                    Recieve Quality Applications
                  </p>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="hexagon">
                  <Box className="hexagon-inner" />
                  <img
                    style={webStyle.cardImage}
                    src={require("../../assets/vertical-card4.svg")}
                  />
                </Box>
                <Box style={webStyle.textBox}>
                  <p style={webStyle.cardText}>Document Verification</p>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          style={{
            position: "relative",
            left: "-300px",
            bottom: "300px",
            zIndex: -1
          }}
        >
          <Box style={commonWebStyle.leftDoottedBackground}>
            <img
              style={{ width: "250px", top: "230px", right: "230px" }}
              src={require("../../assets/dot-bg.svg")}
            />
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainBox: {
    width: "100%",
    height: "1376px",
    maxWidth: "1440px",
    margin: "0 auto",
    marginTop: "150px"
  },
  textHighlight: {
    color: "#2F57A5"
  },
  verticalGrid: {
    width: "100%",
    height: "1376px"
  },
  contentContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  heading: {
    fontSize: "50px",
    fontWeight: 500,
    lineHeight: "120%",
    fontFamily: "Gilroy-ExtraBold",
    fontStyle: "normal",
    textAlign: "left" as const,
    color: "#060A14",
    marginBottom: "32px"
  },
  subText: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "30px",
    letterSpaceing: "0.05px",
    fontFamily: "Gilroy",
    fontStyle: "normal",
    textAlign: "left" as const,
    color: "#334155",
    marginBottom: "48px"
  },
  getStartedButton: {
    fontSize: "13px",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: "ZonaPro",
    textAlign: "left" as const,
    color: "#2F57A5",
    textTransform: "none" as const
  },
  cardImageBox: {
    // position: "absolute" as const,
    padding: "100px 0px 0px 25px"
  },
  cardImage: {
    position: "absolute" as const,
    top: "0px",
    transform: "skewX(-30deg) rotate(30deg) skewY(0deg)",
    width: "90%",
    paddingLeft: "20px",
    objectFit: "none" as const
  },
  textBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  cardText: {
    width: "278px",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "120%",
    textAlign: "center" as const,
    marginLeft: "-30px"
  },
  textWithMargin: {
    marginBottom: "117px"
  }
};
// Customizable Area End
