import React from "react";

import {
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import LandingPageBanner from "./LandingPageBanner.web";
import DashBoardInfoCount from "./DashBoardInfoCount.web";
import ContentImg from "./ContentImg.web";
import ThreeColumnCards from "./ThreeColumnCards.web";
import ApplyNow from "./ApplyNow.web";
import Map from "./Map.web";
import VideoContent from "./VideoContent.web";
import VideoOverlay from "./VideoOverlay.web";
import ImageSliderOverlay from "./ImageSliderOverlay.web";
import Accordion from "./Accordion.web";
import './landingpage.css';
import TabSlider from "./TabSlider.web";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="LandingPage">
        <LandingPageBanner navigation={undefined} id={""}/>
        <DashBoardInfoCount navigation={undefined} id={""} />
        <Accordion navigation={undefined} id={""} />
        <VideoOverlay navigation={undefined} id={""}/>
        <ContentImg navigation={undefined} id={""} />
        <Map navigation={undefined} id={""} />
        <TabSlider navigation={undefined} id={""}/>
        <VideoContent navigation={undefined} id={""}/>  
        <ImageSliderOverlay navigation={undefined} id={""}/>
        <ThreeColumnCards navigation={undefined} id={""} />
        <ApplyNow navigation={undefined} id={""} />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
