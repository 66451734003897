import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  InputLabel,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
// Customizable Area End

import UniversityPageController, { Props } from "./UniversityPageController";

export default class FormSection extends UniversityPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div style={{ width: "100%" }}>
          <Box style={webStyle.mainBox}>
            <h1 style={webStyle.heading}>
              Work with <span style={webStyle.textHighlight}>Studyboard</span>{" "}
              <br />
              <span style={webStyle.textHighlight}>Partnership</span> Request
            </h1>
            <p style={webStyle.subText}>
              Complete the form below and our Partner Relations Team will be in
              touch soon.
            </p>

            <form noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box style={webStyle.inputContainer}>
                    <InputLabel style={webStyle.inputLabel}>
                      Destination Country
                    </InputLabel>
                    <select>
                      <option value="-1">Select an item</option>
                      <option value="India">India</option>
                      <option value="Canada">Canada</option>
                      <option value="USA">USA</option>
                      <option value="UK">UK</option>
                      <option value="Australia">Australia</option>
                      <option value="New Zealand">New Zealand</option>
                    </select>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box style={webStyle.inputContainer}>
                    <InputLabel style={webStyle.inputLabel}>
                      University Name*
                    </InputLabel>
                    <input
                      style={webStyle.textField}
                      type="text"
                      placeholder="University Name*"
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box style={webStyle.inputContainer}>
                    <InputLabel style={webStyle.inputLabel}>
                      Contact Title*
                    </InputLabel>
                    <input
                      style={webStyle.textField}
                      type="text"
                      placeholder="Contact Title*"
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box style={webStyle.inputContainer}>
                    <InputLabel style={webStyle.inputLabel}>
                      Contact Full Name*
                    </InputLabel>
                    <input
                      style={webStyle.textField}
                      type="text"
                      placeholder="Full Name"
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box style={webStyle.inputContainer}>
                    <InputLabel style={webStyle.inputLabel}>
                      Contact Email*
                    </InputLabel>
                    <input
                      style={webStyle.textField}
                      type="text"
                      placeholder="Contact Email*"
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box style={webStyle.inputContainer}>
                    <InputLabel style={webStyle.inputLabel}>
                      Phone Number
                    </InputLabel>
                    <input
                      style={webStyle.textField}
                      type="text"
                      placeholder="Phone Number"
                    />
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box style={webStyle.inputContainer}>
                    <FormControlLabel
                      style={webStyle.inputLabel}
                      control={<Checkbox color="primary" />}
                      label="Check if you have been referred by someone in StudyBoard"
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box style={webStyle.inputContainer}>
                    <InputLabel style={webStyle.inputLabel}>
                      Full Name*
                    </InputLabel>
                    <input
                      style={webStyle.textField}
                      type="text"
                      placeholder="Full Name*"
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box style={webStyle.inputContainer}>
                    <InputLabel style={webStyle.inputLabel}>Email</InputLabel>
                    <input
                      style={webStyle.textField}
                      type="text"
                      placeholder="Email"
                    />
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box style={webStyle.inputContainer}>
                    <InputLabel style={webStyle.inputLabel}>
                      Any additional comments:
                    </InputLabel>
                    <textarea style={webStyle.textarea}>
                      Type your message here
                    </textarea>
                  </Box>
                  <Box style={webStyle.informationText}>
                    StudyBoard is committed to protecting and respecting your
                    privacy, and we’ll only use your personal information to
                    administer your account and to provide the products and
                    services you requested from us. From time to time, we would
                    like to contact you about our products and services, as well
                    as other content that may be of interest to you. If you
                    consent to us contacting you for this purpose, please tick
                    below to say how you would like us to contact you:
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box style={webStyle.inputContainer}>
                    <FormControlLabel
                      style={webStyle.inputLabel}
                      control={<Checkbox color="primary" />}
                      label="I agree to receive other communications from StudyBoard."
                    />
                  </Box>
                  <Box style={webStyle.informationText}>
                    You can unsubscribe from these communications at any time.
                    For more information on how to unsubscribe, our privacy
                    practices, and how we are committed to protecting and
                    respecting your privacy, please review our Privacy Policy.By
                    clicking submit below, you consent to allow StudyBoard to
                    store and process the personal information submitted above
                    to provide you the content requested.
                  </Box>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Button style={webStyle.submitButton}>SUBMIT</Button>
              </Grid>
            </form>
          </Box>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainBox: {
    width: "100%",
    maxWidth: "1312px",
    margin: "0 auto",
    marginTop: "150px"
  },
  heading: {
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "50px",
    lineHeight: "120%",
    textAlign: "center" as const,
    color: "#060A14",
    marginBottom: "32px"
  },
  subText: {
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.05px",
    textAlign: "center" as const,
    color: "#334155",
    marginBottom: "100px"
  },
  textHighlight: {
    color: "#2F57A5"
  },
  inputLabel: {
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "145%",
    letterSpacing: "0.033px",
    marginBottom: "16px",
    color: "#060A14"
  },
  textField: {
    width: "100%",
    height: "56px",
    padding: "10px 24px",
    borderRadius: "8px",
    background: "#F1F5F9",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "145%",
    letterSpacing: "0.033px",
    color: "#64748B",
    border: "none",
    marginBottom: "32px"
  },
  textarea: {
    width: "100%",
    height: "118px",
    padding: "10px 24px",
    borderRadius: "8px",
    background: "#F1F5F9",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "145%",
    letterSpacing: "0.033px",
    color: "#64748B",
    border: "none",
    marginBottom: "32px"
  },
  inputContainer: {
    paddingRight: "32px"
  },
  informationText: {
    width: "1312px",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "145%",
    letterSpacing: "0.033px",
    color: "#64748B"
  },
  submitButton: {
    padding: "16px",
    width: "156px",
    height: "56px",
    background: "#2F57A5",
    color: "white",
    border: "1px solid #2F57A5",
    borderRadius: "8px",
    fontWeight: 700 as const,
    fontSize: "17px",
    lineHeight: "24px",
    fontFamily: "ZonaPro",
    textAlign: "center" as const,
    textTransform: "uppercase" as const,
    marginTop: "40px"
  }
};
// Customizable Area End
