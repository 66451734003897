import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    AccordianBackImage,
    BlueRightArrow,
    StudentDefaultImg,
} from "./assets";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Link } from "react-router-dom";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class Accordian extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  steps = [
    {
      panel: "students",
      header: "Students",
      content: "We believe in your dreams and work hard to make them a reality. Get matched with and apply to programs and University that align with your background, skills, and interests.",
      src: StudentDefaultImg,
    },
    {
      panel: "partners",
      header: "Partners",
      content: "We believe in your dreams and work hard to make them a reality. Get matched with and apply to programs and University that align with your background, skills, and interests.",
      src: StudentDefaultImg,
    },
    {
      panel: "universities",
      header: "Universities",
      content: "We believe in your dreams and work hard to make them a reality. Get matched with and apply to programs and University that align with your background, skills, and interests.",
      src: StudentDefaultImg,
    },
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <section className="sectionStudentHelpMain">
        <div className="sectionStudentHelp sectionTextImage  sectionPadding" style={{marginTop:"25px"}} >
          <Container style={{ maxWidth: "1500px" }}>
            <div className="textImageTop sectionHeading">
              <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={12}>
                  <Box className="headingSubPadding">
                    <h1 className="sectionHeading blueHeading">
                        A Platform That Supports
                    </h1>
                    <h1 className="sectionHeading blackHeading">
                      You End-to-End
                    </h1>
                  </Box>
                  <Box>
                    <p className={"subHeading"}>
                      We believe that education should be accessible to all.
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <div className="textImageBottom">
              <div className="textImageBottomInner">
                <Container style={{
                    maxWidth: "inherit",
                    paddingLeft: "0px",
                    paddingRight:"0"
                }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Timeline align="left" className="accordion-timeline">
                            {this.steps.map((step, index) => (
                                <TimelineItem
                                    key={index}
                                >
                                    <TimelineSeparator className="mobileDNone">
                                        <TimelineDot variant="outlined" style={{borderWidth: "4px"}}
                                        color={
                                        this.state.expandedAccordion === step.panel ? "primary" : "grey"
                                        }
                                        />
                                                
                                        {index !== this.steps.length - 1 && <TimelineConnector
                                        style={{
                                            background: this.state.expandedAccordion === step.panel ? "#3f51b5" : "grey",
                                            margin:"-8px 0px",
                                            width: "4px"
                                        }}
                                        />}
                                    </TimelineSeparator>
                                    <TimelineContent className="TimelineContentMainBox">
                                        <Accordion
                                            expanded={this.state.expandedAccordion === step.panel}
                                            onChange={this.handleChange(step.panel)}
                                            style={{boxShadow:"none",backgroundColor:"transparent"}}
                                        >
                                            <AccordionSummary
                                                aria-controls={`${step.panel}-content`}
                                                id={`${step.panel}-header`}
                                                style={{marginTop: "-16px"}}
                                            >
                                                <Typography className="accordion-heading">
                                                    {step.header}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{flexDirection: "column"}}>
                                                <Typography  className="accordion-content">{step.content}</Typography>
                                                <Link to='/Students' style={{textDecoration: "none"}}>
                                                <button className="accordion-btn">
                                                   <span className="accordion-btnText" style={{fontFamily:"ZonaPro"}}> Learn More</span><span style={{color:"#2F57A5",width:"15px"}}><img src={BlueRightArrow} alt="" /></span>
                                                </button>
                                                </Link>
                                                <img className="dNone mobileDBlock mobileStudentHelpiImg" src={step.src} alt="" />
                                            </AccordionDetails>
                                        </Accordion>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <Grid container spacing={0}>
                        <Grid item sm={12} lg={11}>
                          <div className="textImageRight">
                            <img className="mobileDNone" src={StudentDefaultImg} alt="" />
                          </div>
                        </Grid>
                        <Grid item lg={1} className="accordion-imageGrid">
                          <div style={{
                            position: "relative",
                            top: "-85px",
                            right: "170px",
                            zIndex: -1,
                            }}>                            
                            <img src={AccordianBackImage} alt="" />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </Container>
          <div style={{position:"relative"}}>
            <div className="side-BackGround-Effect-left"></div>
          </div>
        </div>
      </section>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
