// Customizable Area Start
import React from 'react'
import { Link } from "react-router-dom";
import {
    appLogo,
    Facebook,
    Linkedin,
    Twitter,
    YouTube
} from './assets';
import {
    Box,
    Container,
    TextField,
} from "@material-ui/core";


const AppFooter = () => {
    return (
        <>
            <Box className='sectionFooter'>
                <div className="sectionBackgroundLeft" >
                    <Container style={{ maxWidth: "1500px" }}>
                        <footer>
                            <Box className="footerContainer">
                                <Box className='footerRow'>
                                    <Box className="footerLogo footerLeft">
                                        <img src={appLogo} alt="Logo" />
                                    </Box>
                                    <Box className='footerRight'>
                                        <Box className='footerColumn'>
                                            <Box className="footerColumnInner">
                                                <h3 className="footerHeading">Company</h3>
                                                <ul>
                                                    <li><Link to="">Who we are</Link></li>
                                                    <li><Link to="/LeadershipPage">Leadership</Link></li>
                                                    <li><Link to="">Careers</Link></li>
                                                </ul>
                                            </Box>
                                            <Box className="footerColumnInner">
                                                <h3 className="footerHeading">Legal</h3>
                                                <ul>
                                                    <li><Link to="">Privacy</Link></li>
                                                    <li><Link to="">Terms of Service</Link></li>
                                                    <li><Link to="">Copyright</Link></li>
                                                </ul>
                                            </Box>
                                            <Box className="footerColumnInner">
                                                <h3 className="footerHeading">Support</h3>
                                                <ul>
                                                    <li><Link to="">FAQs</Link></li>
                                                    <li><Link to="">Contact us</Link></li>
                                                </ul>
                                            </Box>
                                            <Box className="footerColumnInner">
                                                <h3 className="footerHeading">Discover</h3>
                                                <ul>
                                                    <li><Link to="">Programs</Link></li>
                                                    <li><Link to="">Universities</Link></li>
                                                    <li><Link to="">Registration</Link></li>
                                                </ul>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className='socialLinkandEmail socialLinkBoxPadding'>
                                    <Box className='socialLinkandEmailInner'>
                                        <Box className='socialLinkandEmailColumn footerCopyrightmobile'>
                                            <p style={{opacity:".70"}}>Copyright @StudyBoard</p>
                                        </Box>
                                        <Box className='socialLink socialLinkandEmailColumn'>
                                            <a href=""><img src={Facebook} alt="" /></a>
                                            <a href=""><img src={Linkedin} alt="" /></a>
                                            <a href=""><img src={Twitter} alt="" /></a>
                                            <a href=""><img src={YouTube} alt="" /></a>
                                        </Box>
                                        <Box className='footerSocilaInputBox socialLinkandEmailColumn'><TextField className='emailField' id="outlined-basic" label="Enter your email here" variant="outlined" style={{fontFamily:"Gilroy-ExtraBold"}} /></Box>
                                        <Box className='getUpdateBtn socialLinkandEmailColumn'>
                                            <Link to="" className="blueBtn" href="/" style={{ margin: "0 auto",fontFamily:"ZonaPro" }}>GET UPDATES</Link>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </footer>
                    </Container>
                </div>
            </Box>
            <div style={{ position: "relative" }}>
                <div className="side-BackGround-Effect-left AppfooterBlurShadow"></div>
            </div>

        </>

    )
}

export default AppFooter
// Customizable Area End