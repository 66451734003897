import React from "react";

import {
    Container,
    Box
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    aboveSectionImage1,
    aboveSectionImage2,
    BlueRightArrow,
    patternOnTwoColumn
} from "./assets";
import "./LeadershipPage.css"
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class LeadershipTwoColumnLayout extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <div className="VideoContentSection" style={{ marginTop: '20px' }}>
                    <div className="sectionBackgroundLeft" >
                        <Container style={{ maxWidth: "1500px" }}>
                            <div className="textBelowVideoBanner">
                                <div style={{ position: "relative" }}>
                                    <div style={webStyles.leftBlueBackground} />
                                </div>
                                <p>
                                    After facing many challenges and obstacles on their own study abroad journeys, the Basiri brothers saw an opportunity to break down barriers and democratize education. Together they created StudyBoard to make their dream to educate the world a reality.
                                    Today Meti, Martin, and Massi have built an expanded leadership team with more than a century of collective experience and expertise in higher education, technology, security, engineering, HR, finance, marketing, customer success, and more.
                                </p>
                            </div>
                            <Box className="twoColumnContainer">
                                <div className="firstRow">
                                    <div className="columnDescriptionSection">
                                        <h1>A Message <br /> <span className="leadershipBlackHeader">From Our </span>CEO</h1>
                                        <p>
                                            “My brothers–Martin and Massi–and I founded StoryBoard with one mission in mind: empower people
                                            around the world to study abroad and access the best education.
                                            Every international student journey is unique, filled with boundless opportunities
                                            and a few challenges along the way.
                                            We strive to deliver new solutions, innovations, and support,
                                            allowing students to take hold of those opportunities. In all we do, we aim to support their
                                            success and the long-term prosperity of the study abroad sector.”
                                        </p>
                                        <h4>– Meti Basiri</h4>
                                    </div>
                                    <div className="ceoImageContainer">
                                        <img src={aboveSectionImage2} alt="" />
                                        <h1>CEO</h1>
                                    </div>
                                </div>
                                <div className="secondRow">
                                    <div className="rowTwoImageContainer">
                                        <img src={aboveSectionImage1} alt="" />
                                        <img src={patternOnTwoColumn} alt="" className="twoColumnPatternImage" />
                                    </div>
                                    <div className="columnDescriptionSection">
                                        <h1><span className="leadershipBlackHeader">Advisory</span> Board</h1>
                                        <p className="">
                                            As a firm believer in the difference education makes in students’ lives, Jo Johnson has dedicated his life to innovating
                                            and advancing post-secondary education globally. He later attended Cabinet as Minister of State for Universities, Science,
                                            Research and Innovation. Currently, he is the Chairman, International at StudyBoard and Chairman of Tes Global,
                                            as well as a Senior Fellow at the Harvard Kennedy School and President’s Professorial Fellow at King’s College, London.
                                        </p>
                                        <div className="twoColumnLinkButton">Learn More About Our Leaders <img src={BlueRightArrow} alt="" style={{ marginLeft: '8px' }} /></div>
                                    </div>
                                </div>
                            </Box>
                        </Container>
                    </div>
                </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const webStyles = {
    leftBlueBackground: {
        position: "absolute" as const,
        width: "268px",
        height: "268px",
        left: "-660px",
        bottom: '40px',
        background: "#2F57A5",
        filter: "blur(80px)",
        opacity: ".6",
    },
};
// Customizable Area End
