import React from "react";

import {
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import DashBoardInfoCount from "./DashBoardInfoCount.web";
import ThreeImageContent from "./ThreeImageContent.web";
import './AboutUsPage.css'; 
import ThreeColumnCards from "./ThreeColumnCards.web";
import AboutUsVideoBanner from "./AboutUsVideoBanner.web";
import AboutUsVideoContent from "./AboutUsVideoContent.web";
import AboutUsVideoOverlay from "./AboutUsVideoOverlay.web";
import Map from "./Map.web";
import ApplyNow from "./ApplyNow.web";
import './landingpage.css';
import YearWiseSlider from "./YearWiseSlider.web";
import AboutUsSteeper from "./AboutUsSteeper.web";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class AboutUsPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="AboutUsPage" onScroll={this.handleScroll}>
        <AboutUsVideoBanner navigation={undefined} id={""}/>
        <DashBoardInfoCount navigation={undefined} id={""} />
        <ThreeImageContent navigation={undefined} id={""} />
        <AboutUsVideoContent navigation={undefined} id={""}/>
        <AboutUsVideoOverlay navigation={undefined} id={""}/>
        <AboutUsSteeper navigation={undefined} id={""}/>
        <Map navigation={undefined} id={""} />
        <YearWiseSlider navigation={undefined} id={""}/>
        <ThreeColumnCards navigation={undefined} id={""} />
        <ApplyNow navigation={undefined} id={""} />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
