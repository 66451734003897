import React from "react";

import {
  Container,
  Box
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
// Customizable Area End

import UniversityPageController, { Props } from "./UniversityPageController";

export default class ApplyNow extends UniversityPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className="sectionApplyBoard sectionPadding">
        <Container style={webStyle.mainContainer}>
          <Box className="ApplyNowImg">
            <img
              className="ApplyNowBgImg"
              src={require("../../assets/ApplyNowImg.jpg")}
              alt="Image"
            />
            <Box className="" style={webStyle.applyNowBox}>
              <Box
                className="ApplyNowImgOverlayInner"
                style={webStyle.imageOverlay}
              >
                <h2 className="ApplyNowText" style={webStyle.applyNowText}>
                  Ready to get started with StudyBoard?
                </h2>
                <Box className="ApplyNowLinkBox">
                  <Link to="" className="ApplyNowLink">
                    Apply Now
                  </Link>
                  <img src={require("../../assets/arrow-blue.svg")} alt="" />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{ position: "relative" }}>
            <Box className="bgBottomApplyNow">
              {" "}
              <img src={require("../../assets/bgBottomApplyNow.svg")} alt="" />
            </Box>
          </Box>
        </Container>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    maxWidth: "1500px"
  },
  applyNowBox: {
    width: "473px",
    height: "480px",
    padding: "78px 45px",
    position: "absolute" as const,
    top: "-25px",
    right: "8%",
    background: "#FFF",
    borderRadius: "24px"
  },
  applyNowText: {
    fontSize: "50px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "120%"
  },
  imageOverlay: {
    position: "relative" as const,
    top: "60px"
  }
};
// Customizable Area End
