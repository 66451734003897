import React from "react";

import {
  Container,
  Box
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ApplyNowImg, BlueRightArrow, bgBottomApplyNow } from "./assets";
import { Link } from "react-router-dom";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class ApplyNow extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="sectionApplyBoard sectionPadding">
        <Container style={{ maxWidth: "1500px" }}>
          <div className="ApplyNowImg">
            <img className="ApplyNowBgImg" src={ApplyNowImg} alt="Image" />
            <div className="ApplyNowImgOverlay">
              <div className="ApplyNowImgOverlayInner">
                <h2 className="ApplyNowText">
                  Ready to get started with StudyBoard?
                </h2>
                <Box className="ApplyNowLinkBox">
                  <Link to="" className="ApplyNowLink">
                    Apply Now
                  </Link>
                  <img src={BlueRightArrow} alt="" />
                </Box>
              </div>
            </div>
          </div>
          <div style={{position:"relative"}}>
            <div className="bgBottomApplyNow"> <img src={bgBottomApplyNow} alt="" /></div>
          </div>
        </Container>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
