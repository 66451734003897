import React from "react";

import {
    Box,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {playButton} from "./assets";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class TabSlider extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

// Customizable Area Start
// Customizable Area End

render() {
    const filters = ['All ', '/ Students ', '/ Partners ', '/ Universities']

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                                        
                }
            },
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            
     
        ]
    };


    return (
        // Customizable Area Start
        <>
            <div className="sliderHeaderContainer">
                <Box style={{ marginBottom: '40px' }} className={"sliderHead"}>
                    <h1 className="sectionHeading blueHeading tabSliderHeader" >
                        What Our Partners {' '}
                        <span className="sectionHeading blackHeading tabSliderHeader">
                            Have to Say
                        </span>
                    </h1>
                    <p
                        className={"subHeading tabSliderPara"}
                    >
                        We believe that education should be accessible to all.
                    </p>
                    <div style={{position:"relative"}}>
                        <div className="side-BackGround-Effect-left"></div>
                    </div>

                    {/* tabs */}

                    <div style={{ textAlign: 'center' }}>
                        <span className={"filterByHeading"}>Filter By</span>
                        {filters.map((item, index) => (
                            <span key={index} className={this.state.value === item ? "activeFiler" : "filterOptions"} onClick={() => this.handleClick(item)}>{item}</span>
                        ))}
                    </div>
                </Box>
                <div className={'sliderContainer'}>
                    <div className={'slideInnerContainer'}>
                        <Slider {...settings}>
                            {
                                this.state.videosData.length > 0 ? this.state.videosData.map((el: any, elIndex: any) => (
                                    <div key={elIndex} className={"videoContainer"}>
                                        {this.state.videoIdToPlay === el.id && this.state.isFilterVideoPlaying ?
                                            <video
                                                loop
                                                muted
                                                autoPlay
                                                controls
                                                className={'videoframe'}
                                            >
                                                <source src={el.source} type="video/mp4" />
                                            </video>
                                            :
                                            null}

                                        {this.state.videoIdToPlay !== el.id &&
                                            <video
                                                loop
                                                muted
                                                className={'videoframe'}
                                            >
                                                <source src={el.source} type="video/mp4" />
                                            </video>}


                                        {this.state.videoIdToPlay !== el.id && <img src={playButton} alt="" className={"playIcon"} onClick={() => this.toggleVidPlay(el.id)} />}
                                        {this.state.videoIdToPlay !== el.id ?
                                            <div className={"textOnVide"}>
                                                <p className="para1">{el.partnerAs}</p>
                                                <p className="para2">{el.as}</p>
                                            </div> : null}
                                    </div>
                                )) : ''
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </>
        // Customizable Area End
    );
}
}

// Customizable Area Start
// Customizable Area End

