import React from "react";

import {
    Container,
    Box,
    Grid,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    BlueRightArrow
} from "./assets";
import { Link } from "react-router-dom";
import VisibilitySensor from 'react-visibility-sensor';
import './landingpage.css'
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class ThreeImageContent extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const images = this.imagesArray.map((item) => (
            <VisibilitySensor key={item.id} onChange={() => this.handleImageChange(item.id)} data-test-id="visibleNode">
                <div className="ThreeimageContainer">
                    <img
                        src={item.src}
                        className="image"
                    />
                </div>
            </VisibilitySensor>
        ));
        return (
            // Customizable Area Start
            <section id="ThreeImageContent" className="ThreeImageContent sectionpadding">
                <Container className="containerMaxWidth">
                    <Box className="ThreeImageContentWrapper">
                        <Grid container spacing={6}>
                            <Grid item lg={6} md={12} sm={12} spacing={6}>
                                <Box className="ContentSide">
                                    <VisibilitySensor onChange={this.handleVisibilityChange}>
                                        {/* Scrollable content goes here */}
                                        <div className={`sticky-content ${this.state.isVisible ? 'visible' : ''}`}>
                                            {this.renderImageContent(this.state.activeImage)}
                                        </div>
                                    </VisibilitySensor>
                                </Box>
                            </Grid>
                            <Grid item lg={6} md={12} sm={12}>
                                <Box className="ImageSide">
                                    <Grid container spacing={4}>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <div className="imgSideInner">
                                                {/* <img src={OurStoryImg1} alt="" /> */}
                                                {images}
                                            </div>
                                        </Grid>
                                        {/* <Grid item lg={12} md={12} sm={12}>
                                            <div className="imgSideInnder">
                                                <img src={OurStoryImg2} alt="" />
                                            </div>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <div className="imgSideInnder">
                                                <img src={OurStoryImg3} alt="" />
                                            </div>
                                        </Grid> */}
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </section>
            // Customizable Area End
        );
    }
    renderImageContent = (index: number) => {
        switch (index) {
            case 1:
                return <div>
                    <Box className="ContentSide">
                        <div className="sectionHeading">
                            <Box style={{ marginBottom: "32px" }}>
                                <span className="sectionHeading blackHeading">
                                    Our
                                </span>&nbsp;
                                <span className="sectionHeading blueHeading">
                                    Story
                                </span>
                            </Box>
                        </div>
                        <p>
                            SupplyBoard simplifies the study abroad search, application, and acceptance process by connecting international students, Partners, and academic institutions on one platform. Founded in 2015 by brothers Martin, Meti, and Massi Basiri, we’ve built partnerships with 1,750+ primary, secondary, and post-secondary educational institutions, and work with 10,000+ Partners to drive diversity on campuses across Canada, the United States, the United Kingdom, Australia, and Ireland.
                            SupplyBoard has grown to become the world’s largest online platform for international student recruitment, assisting more than 600,000 students with their educational journeys. In 2022, SupplyBoard was named one of the fastest-growing technology companies in Canada by Deloitte, ranking #30 on the Deloitte Technology Fast 50 and #170 on the 2022 Deloitte Technology Fast 500 list. This marked the fourth consecutive year that SupplyBoard was recognized by Deloitte as one of Canada’s fastest-growing companies.
                        </p>
                        <div className="Button">
                            <Link to="" className="blueBtn blueBtnRIghtArrow">Learn More About Our Leaders<img src={BlueRightArrow} alt="" /></Link></div>
                    </Box>
                </div>;
            case 2:
                return <div>
                    <Box className="ContentSide">
                        <div className="sectionHeading">
                            <Box style={{ marginBottom: "32px" }}>
                                <span className="sectionHeading blackHeading">
                                    Our 
                                </span>&nbsp;
                                <span className="sectionHeading blueHeading">
                                    Story 1
                                </span>
                            </Box>
                        </div>
                        <p>
                            of the fastest-growing technology companies in Canada by Deloitte, ranking #30 on the Deloitte Technology Fast 50 and #170 on the 2022 Deloitte Technology Fast 500 list. This marked the fourth consecutive year that SupplyBoard was recognized by Deloitte as one of Canada’s fastest-growing companies.
                        </p>
                        <div className="Button">
                            <Link to="" className="blueBtn blueBtnRIghtArrow">Learn More About Our Leaders<img src={BlueRightArrow} alt="" /></Link></div>
                    </Box>
                </div>;
            case 3:
                return <div>
                    <Box className="ContentSide">
                        <div className="sectionHeading">
                            <Box style={{ marginBottom: "32px" }}>
                                <span className="sectionHeading blackHeading">
                                    Our
                                </span>&nbsp;
                                <span className="sectionHeading blueHeading">
                                    Story 2
                                </span>
                            </Box>
                        </div>
                        <p>
                            onal student recruitment, assisting more than 600,000 students with their educational journeys. In 2022, SupplyBoard was named one of the fastest-growing technology companies in Canada by Deloitte, ranking #30 on the Deloitte Technology Fast 50 and #170 on the 2022 Deloitte Technology Fast 500 list. This marked the fourth consecutive year that SupplyBoard was recognized by Deloitte as one of Canada’s fastest-growing companies.
                        </p>
                        <div className="Button">
                            <Link to="" className="blueBtn blueBtnRIghtArrow">Learn More About Our Leaders<img src={BlueRightArrow} alt="" /></Link></div>
                    </Box>
                </div>;
            case 4:
                return <div>
                    <Box className="ContentSide">
                        <div className="sectionHeading">
                            <Box style={{ marginBottom: "32px" }}>
                                <span className="sectionHeading blackHeading">
                                    there
                                </span>&nbsp;
                                <span className="sectionHeading blueHeading">
                                    Story
                                </span>
                            </Box>
                        </div>
                        <p>
                            SupplyBoard simpr international student recruitment, assisting more than 600,000 students with their educational journeys. In 2022, SupplyBoard was named one of the fastest-growing technology companies in Canada by Deloitte, ranking #30 on the Deloitte Technology Fast 50 and #170 on the 2022 Deloitte Technology Fast 500 list. This marked the fourth consecutive year that SupplyBoard was recognized by Deloitte as one of Canada’s fastest-growing companies.
                        </p>
                        <div className="Button">
                            <Link to="" className="blueBtn blueBtnRIghtArrow">Learn More About Our Leaders<img src={BlueRightArrow} alt="" /></Link></div>
                    </Box>
                </div>;
            default:
                return null;
        }
    };
}

// Customizable Area Start
// Customizable Area End
