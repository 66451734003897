import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
// Customizable Area End

import UniversityPageController, {
  Props,
  commonWebStyle
} from "./UniversityPageController";

export default class ReviewsSection extends UniversityPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box
          style={{
            position: "relative",
            right: "414px",
            top: "377px",
            zIndex: -1
          }}
        >
          <Box style={commonWebStyle.rightDoottedBackground}>
            <img
              style={{ width: "250px", top: "230px", right: "230px" }}
              src={require("../../assets/dot-bg.svg")}
            />
          </Box>
        </Box>
        <Box style={{ width: "100%" }}>
          <Box style={webStyle.mainBox}>
            <h1 style={webStyle.heading}>
              A Relationship Built on <br />
              <span style={webStyle.textHighlight}>Trust and Credibility</span>
            </h1>
            <Box style={webStyle.quoteBox}>
              <img
                style={webStyle.quoteImage}
                src={require("../../assets/format-quote-open.svg")}
              />
            </Box>
            <Box style={webStyle.reviewBox}>
              <Box style={webStyle.imageBox}>
                <img src={require("../../assets/review-user.png")} />
              </Box>
              <Box>
                <p style={webStyle.reviewContent}>
                  It was important for me to get an education that would provide
                  me with real-world skills. Studying abroad in Canada is the
                  best decision I’ve ever made, and an experience I wouldn't
                  trade for anything.
                </p>
                <p style={webStyle.reviewDescription}>
                  — Alison Xu, Wilfrid Laurier Graduate – International Student
                </p>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainBox: {
    width: "100%",
    maxWidth: "1440px",
    margin: "0 auto",
    marginTop: "150px"
  },
  heading: {
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "50px",
    lineHeight: "120%",
    textAlign: "center" as const,
    color: "#060A14",
    marginBottom: "100px"
  },
  textHighlight: {
    color: "#2F57A5"
  },
  reviewBox: {
    backgroundColor: "#F8FAFC",
    borderRadius: "40px",
    padding: "120px 80px",
    display: "flex",
    flexFlow: "row",
    gap: "70px"
  },
  imageBox: {
    width: "238px",
    height: "238px",
    borderRadius: "50%"
  },
  reviewContent: {
    width: "918px",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "120%",
    color: "#060A14",
    marginBottom: "48px",
    textAlign: "left" as const
  },
  reviewDescription: {
    width: "918px",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "30px",
    letterSpacing: "0.05px",
    color: "#334155",
    marginBottom: "48px",
    textAlign: "left" as const
  },
  quoteBox: {
    position: "relative" as const
  },
  quoteImage: {
    position: "absolute" as const,
    bottom: "-60px",
    left: "140px"
  }
};
// Customizable Area End
