import React from "react";
// Customizable Area Start
import { Grid, Box } from "@material-ui/core";
// Customizable Area End

import UniversityPageController, { Props } from "./UniversityPageController";

export default class ThreeCardSection extends UniversityPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  features = [
    {
      title: "Vetted, Offer-Ready Applications, Delivered",
      description:
        "Reduce your workload on application processing and follow-ups.",
      icon: "star-check-outline.svg"
    },
    {
      title: "Putting Students First",
      description:
        "Founded by students for students—we care about every aspect of the student journey.",
      icon: "account-outline.svg"
    },
    {
      title: "Flexible Delivery",
      description:
        "We match your processes and requirements—there is no need for integration.",
      icon: "moped-outline.svg"
    },
    {
      title: "Work With You, For You",
      description:
        "Our aim is to free up your time and resources. We help you recruit efficiently and effectively.",
      icon: "sitemap-outline.svg"
    }
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div style={{ width: "100%" }}>
          <Box style={webStyle.mainBox}>
            <h1 style={webStyle.heading}>
              An Easy-to-Use Platform Built to <br /> Deliver{" "}
              <span style={webStyle.textHighlight}>Quality Applications</span>{" "}
              and More
            </h1>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} xs={12}>
                {this.features.map((feature, index) => (
                  <Box key={index} style={webStyle.feturesListItem}>
                    <Box style={webStyle.iconBox}>
                      <img src={require("../../assets/" + feature.icon)} />
                    </Box>
                    <Box>
                      <p style={webStyle.fetureTitle}>{feature.title}</p>
                      <p style={webStyle.fetureDescription}>
                        {feature.description}
                      </p>
                    </Box>
                  </Box>
                ))}
              </Grid>
              <Grid item lg={6} md={6} xs={12} style={webStyle.imageBox}>
                <img src={require("../../assets/feature-image.svg")} />
              </Grid>
            </Grid>
          </Box>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainBox: {
    width: "100%",
    maxWidth: "1238px",
    margin: "0 auto",
    marginTop: "150px"
  },
  heading: {
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "50px",
    lineHeight: "120%",
    textAlign: "center" as const,
    color: "#060A14",
    marginBottom: "128px"
  },
  textHighlight: {
    color: "#2F57A5"
  },
  feturesList: {
    width: "100%"
  },
  feturesListItem: {
    width: "100%",
    display: "flex",
    flexFlow: "row",
    marginBottom: "48px"
  },
  iconBox: {
    width: "40px",
    marginRight: "24px"
  },
  fetureTitle: {
    fontSize: "22px",
    fontWeight: 500,
    lineHeight: "38px",
    letterSpacing: "0.055px",
    color: "#1E293B",
    marginBottom: "7px"
  },
  fetureDescription: {
    width: "337px",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "145%",
    letterSpacing: "0.033px",
    color: "#64748B"
  },
  imageBox: {
    display: "flex",
    justifyContent: "end",
    alignItems: "start"
  }
};
// Customizable Area End
