export const backgroundImgRIghtBottom = require("../assets/backgroundImgRIghtBottom.svg");
export const GroupDisucssionImg = require("../assets/GroupDisucssionImg.svg");
export const findIcon = require("../assets/findIcon.svg");
export const Group = require("../assets/Group.svg");
export const scholarship = require("../assets/scholarship.svg");
export const GroupDiscussion2 = require("../assets/GroupDiscussion2.svg");
export const dataDriven = require("../assets/dataDriven.svg");
export const Knowledge = require("../assets/Knowledge.svg");
export const Computer = require("../assets/Computer.svg");
export const rightArrow = require("../assets/rightArrow.svg");
export const BlueRightArrow = require("../assets/BlueRightArrow.svg");
export const Students = require("../assets/Students.svg");
export const Universities = require("../assets/Universities.svg");
export const Partners = require("../assets/Partners.svg");
export const ApplyNowImg = require("../assets/ApplyNowImg.svg");
export const MapImg = require("../assets/MapImg.svg");
export const whileRIghtArrow = require("../assets/whileRIghtArrow.svg");
export const homeBanner = require("../assets/homeBanner.png");
export const StudentBtnIcon = require("../assets/StudentBtnIcon.svg");
export const PartnersBtnIcon = require("../assets/PartnersBtnIcon.svg");
export const UniversityBtnIcon = require("../assets/UniversityBtnIcon.svg");
export const flag1 = require("../assets/flag1.svg");
export const  flag2= require("../assets/flag2.svg");
export const flag3 = require("../assets/flag3.svg");
export const flag4 = require("../assets/flag4.svg");
export const flag5 = require("../assets/flag5.svg");
export const flag6 = require("../assets/flag6.svg");
export const StudentDefaultImg = require("../assets/StudentDefaultImg.png");
export const AccordianBackImage = require("../assets/Accordian.svg");
export const playButton = require("../assets/playButton.svg")
export const playIcon2 = require("../assets/playIcon7.svg");
export const playIconLarge = require("../assets/playIcon16.svg")
export const bgDotsLeft = require("../assets/bgDotsLeft.svg")
export const bgDotsSmall = require("../assets/bgDotsSmall.svg")
export const bgBottomApplyNow = require("../assets/bgBottomApplyNow.svg")
export const overlayBackground = require("../assets/overlayImage.svg")
export const OurStoryImg1 = require("../assets/OurStoryImg1.svg")
export const OurStoryImg2 = require("../assets/OurStoryImg2.svg")
export const OurStoryImg3 = require("../assets/OurStoryImg3.svg")
export const AboutUsStepper_IMG1 = require("../assets/AboutUsStepper_IMG1.svg")
export const AboutUsStepper_IMG2 = require("../assets/AboutUsStepper_IMG2.svg")
export const AboutUsStepper_IMG3 = require("../assets/AboutUsStepper_IMG3.svg")
export const AboutUsStepper_IMG4 = require("../assets/AboutUsStepper_IMG4.svg")
export const AboutUsStepper_IMG5 = require("../assets/AboutUsStepper_IMG5.svg")
export const AboutUsStepper_IMG6 = require("../assets/AboutUsStepper_IMG6.svg")

export const  MobileBannerSubtract = require("../assets/MobileBannerSubtract.png")
export const roudedNormal = require("../assets/view_view7.svg")
export const roudedhighilighted = require("../assets/view_view8.svg")

//Leadership 
export const LeadershipsliderImg1 = require("../assets/view_view1.svg")
export const LeadershipsliderImg2 = require("../assets/view_view2.svg")
export const LeadershipsliderImg3 = require("../assets/view_view3.svg")
export const aboveSectionImage1 = require("../assets/view_view4.svg")
export const aboveSectionImage2 = require("../assets/view_view5.svg")
export const patternOnTwoColumn = require("../assets/view_view34.svg")
export const applyNowImageLeadership = require("../assets/ApplyNowImg.jpg")