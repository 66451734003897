import React from "react";
// Customizable Area Start
import { Grid, Box } from "@material-ui/core";
// Customizable Area End

import StudentPageController, {
  Props,
  commonWebStyle
} from "./StudentPageController";

export default class PopularFieldsofStudy extends StudentPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  popularFields = [
    {
      name: "Engineering",
      icon: "engineering.svg",
      courses: "40"
    },
    {
      name: "Sciences",
      icon: "sciences.svg",
      courses: "30"
    },
    {
      name: "Arts",
      icon: "arts.svg",
      courses: "50"
    },
    {
      name: "Business",
      icon: "business.svg",
      courses: "20"
    },
    {
      name: "Law",
      icon: "law.svg",
      courses: "45"
    },
    {
      name: "English Language",
      icon: "english-language.svg",
      courses: "65"
    },
    {
      name: "Medicine",
      icon: "medicine.svg",
      courses: "15"
    },
    {
      name: "Economics",
      icon: "economics.svg",
      courses: "5"
    }
  ];

  sliceIntoChunks(arr: Array<object>, chunkSize: number) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }
  // Customizable Area End

  render() {
    const slicedPopularFields = this.sliceIntoChunks(this.popularFields, 5);
    console.log("slicedPopularFields: ", slicedPopularFields);
    return (
      // Customizable Area Start
      <Box style={{ margin: "0 auto", width: "100%", maxWidth: "1442" }}>
        <Grid container spacing={2} style={{ marginTop: "150px" }}>
          <Grid item xs={12}>
            <h1 style={webStyle.popularFieldofStudyHeading}>
              Explore Popular Fields <br /> of{" "}
              <span style={webStyle.textHighlight}>Study</span>
            </h1>
          </Grid>
        </Grid>
        <Box style={webStyle.fieldsOfStudyBlockContainer}>
          {slicedPopularFields.map((fieldsArray, fieldIndex) => {
            return (
              <Box
                key={fieldIndex}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexFlow: "row"
                }}
              >
                {fieldsArray.map((element: any, index) => (
                  <Box style={webStyle.fieldsOfStudyBlock} key={index}>
                    <img
                      style={webStyle.fieldsOfStudyBlockImg}
                      src={require("../../assets/" + element.icon)}
                    />
                    <p style={webStyle.fieldsOfStudyBlockPara}>
                      {element.name}
                    </p>
                    <span style={webStyle.fieldsOfStudyBlockSpan}>
                      {element.courses} Courses
                    </span>
                  </Box>
                ))}
              </Box>
            );
          })}
        </Box>
        <Box style={{ position: "relative" }}>
          <Box style={commonWebStyle.rightBlueBackground} />
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  textHighlight: {
    color: "#2F57A5"
  },
  popularFieldofStudyHeading: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "50px",
    lineHeight: "120%",
    textAlign: "center" as const,
    color: "#060A14",
    marginBottom: "100px"
  },
  fieldsOfStudyBlockContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "150px",
    flexFlow: "column"
  },
  fieldsOfStudyBlock: {
    width: "270px",
    padding: "32px 56px",
    border: "1px solid #D7E1F4",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    margin: "8px"
  },
  fieldsOfStudyBlockImg: {
    width: "64px",
    height: "64px",
    marginBottom: "8px"
  },
  fieldsOfStudyBlockPara: {
    fontWeight: 500,
    fontSize: "26px",
    lineHeight: "30px",
    color: "#060A14",
    whiteSpace: "nowrap" as const
  },
  fieldsOfStudyBlockSpan: {
    fontWeight: 500,
    fontSize: "17px",
    lineHeight: "200%",
    letterSpacing: "0.043px",
    color: "#64748B"
  }
};
// Customizable Area End
